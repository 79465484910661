<template>
                <ul class="nav nav-tabs justify-content-center mainNav mb-5">
                    <li class="nav-item">
                        <a :class="{active: selectedComponent === 'listeaza-facturi', 'nav-link': true}"
                           href="#"
                           @click="setSelectedComponent('listeaza-facturi')">Facturi de incasat</a>
                    </li>

                    <li class="nav-item">
                        <a href="#" :class="{active: selectedComponent === 'facturi-platite', 'nav-link': true}"
                           @click.prevent="setSelectedComponent('facturi-platite')">Plati finalizate</a>
                    </li>
                    <li class="nav-item">
                        <a :class="{active: selectedComponent === 'adauga-factura', 'nav-link': true}" href="#"
                           @click.prevent="setSelectedComponent('adauga-factura')">Adauga o factura</a>
                    </li>
                </ul>
    <div class="container-fluid">
        <div class="row">

        <component :is="selectedComponent" @plateste-factura="platesteFactura" v-bind="properties"></component>


        </div>
    </div>
</template>

<script>

import AdaugaFactura from "@/components/de_incasat/AdaugaFactura";
import AdaugaPlata from "@/components/de_incasat/AdaugaPlata";
import ListeazaFacturi from "@/components/de_incasat/ListeazaFacturi";
import FacturiPlatite from "@/components/de_incasat/FacturiPlatite";

export default {
    name: "FacturiDeIncasatView",
    components: {
        AdaugaFactura,
        AdaugaPlata,
        ListeazaFacturi,
        FacturiPlatite
    },
    data() {
        return {
            selectedComponent: 'listeaza-facturi',
            idFactura: '',
        }
    },
    methods: {
        setSelectedComponent(component) {
            this.selectedComponent = component;
        },
        platesteFactura(idFactura) {
            this.selectedComponent = 'adauga-plata';
            this.idFactura = idFactura;
        }
    },
    computed: {
        properties() {
            if (this.selectedComponent === 'adauga-plata') {
                return {idFactura: this.idFactura}
            }
            return {};
        }
    }
}
</script>

<style scoped lang="scss">

</style>


<!--
facturi de incasat in loc de lista facturi
sterge plata

centralizator
	imparte pe factura sau aviz + total general


document devine factura sau aviz la adaugare

facturi de plata
	sterge plati

	culori la adauga + stergere
	lista facturi => facturi de platit



	data n poate sa fie in viitor
-->