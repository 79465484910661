<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <form action="#" method="post" @submit.prevent="adaugaPlata">
                    <h2>Adauga plata</h2>

                    <div class="mb-3">
                        Factura <strong>{{ detaliiFactura.numar_factura }}</strong> din
                        <strong>{{ detaliiFactura.data_factura }}</strong> <br>
                        Emisa de: {{ detaliiFactura.furnizor }}<br>
                        Beneficiar: {{ detaliiFactura.beneficiar }}<br>
                        Valoare factura: <strong>{{ detaliiFactura.valoare_factura }}</strong><br>
                        Detalii: {{ detaliiFactura.detalii_factura }}
                    </div>

                    <div class="mb-3">
                        <label for="tip_plata" class="form-label">Tip document</label>
                        <select name="tip_plata" id="tip_plata" class="form-select" ref="tip_plata">
                            <option value="op">OP</option>
                            <option value="chitanta">Chitanta</option>
                            <option value="cash">Cash</option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="numar_document" class="form-label">Numar document</label>
                        <input required type="text" name="numar_document" id="numar_document" class="form-control"
                               ref="numar_document">
                    </div>

                    <div class="mb-3">
                        <label for="data_plata" class="form-label">Data platii</label>
                        <input required type="date" name="data_plata" id="data_plata" class="form-control"
                               ref="data_plata">
                    </div>

                    <div class="mb-3">
                        <label for="valoare_plata" class="form-label">Valoare platita</label>
                        <input required type="number" name="valoare_plata" id="valoare_plata" class="form-control"
                               ref="valoare_plata" step=".01" min="0">
                    </div>

                    <p v-if="displayAnswer"
                       :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                        {{ answer }}</p>

                    <button :disabled="buttonDisabled" type="submit" class="btn btn-primary">Adauga plata</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {API_URL} from '@/config';

export default {
    name: "AdaugaPlata",
    props: {
        idFactura: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            detaliiFactura: {},
            displayAnswer: false,
            alertSuccess: false,
            alertDanger: false,
            answer: '',
            buttonDisabled: false,
        }
    },
    methods: {
        getDetaliiFactura() {
            fetch(API_URL + '/detalii-factura.php', {
                method: 'post',
                body: JSON.stringify({idFactura: this.idFactura}),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.detaliiFactura = answer.msg[0]
                    } else {
                        alert('Nu am putut selecta datele facturii');
                    }
                });
        },
        adaugaPlata() {
            let body = {
                id_factura: this.detaliiFactura.id,
                numar_document: this.$refs.numar_document.value,
                tip_document: this.$refs.tip_plata.value,
                data_plata: this.$refs.data_plata.value,
                valoare_plata: this.$refs.valoare_plata.value,
            };
            if (new Date(body.data_plata) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/adauga-plata.php', {
                    method: 'post',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-type': 'application/json',
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;
                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;
                    })
                    .catch(e => console.log(e));
            }
        }
    },
    mounted() {
        this.getDetaliiFactura();
    }
}
</script>

<style scoped>

</style>