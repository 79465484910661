<template>
    <tr :id="'tr_' + angajat.id" :data-angajat="angajat.id" :class="{'d-none': dNone}">
        <td class="dnp">{{ index + 1 }}.</td>
        <td nowrap>{{ angajat.fname }}</td>
        <td nowrap>{{ angajat.lname }}</td>
        <td nowrap>{{ angajat.data_inceput }}</td>
        <td nowrap>{{ angajat.data_sfarsit }}</td>
        <td nowrap>{{ angajat.zile_lucratoare_pana_azi }} / {{ angajat.zile_lucratoare_perioada }}</td>
        <td nowrap> {{ angajat.zile_lucrate }}</td>
        <td nowrap>{{ angajat.zile_libere }}</td>
        <td nowrap class="text-end">{{
                parseFloat(angajat.pe_zi).toLocaleString('ro-Ro', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }}
        </td>
        <td nowrap class="text-end">{{
                parseFloat(angajat.de_plata_zi).toLocaleString('ro-Ro', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }}
        </td>
        <td nowrap class="text-end">
            {{
                parseFloat(angajat.de_plata_septembrie).toLocaleString('ro-Ro', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            }}
        </td>
        <td class="text-end dnp" nowrap>
            <div v-if="!this.platit">

                <a href="#" @click.prevent="stergeFactura('angajati', angajat.id)">Sterge</a> /
                <a href="#" @click.prevent="$parent.adaugaPlata(angajat.id)">Avans</a> /
                <a href="#" @click.prevent="$parent.editeazaAngajat(angajat.id)">Editeaza</a> /
                <a href="#" @click.prevent="tipareste">Tipareste</a>
            </div>
            <div v-else>
                <a href="#" @click.prevent="$parent.editeazaAngajat(angajat.id)">Editeaza</a> /
                <a href="#" @click.prevent="tipareste">Tipareste</a>
            </div>
        </td>
    </tr>
    <tr :data-angajat="angajat.id">
        <td colspan="12" class="text-end">
            Rest de plata: {{
                parseFloat(restDePlataZi).toLocaleString('ro-Ro', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                })
            }} / {{
                parseFloat(restDePlataSeptembrie).toLocaleString('ro-Ro', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                })
            }}
        </td>
    </tr>

    <tr v-if="(angajat.plati.length > 0 || Object.keys(angajat.plati).length > 0)  || (angajat.libere.length > 0 || Object.keys(angajat.libere).length >0 )"
        :data-angajat="angajat.id">
        <td colspan="3" v-if="(angajat.plati.length > 0 || Object.keys(angajat.plati).length > 0)">
            <p class="text-center m-0 p-0">Plati intermediare</p>
            <table class="table table-sm text-success">
                <thead>
                <tr>
                    <th>Data plata</th>
                    <th>Suma platita</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="plata in angajat.plati" :key="plata.id_plata" :data-plata="plata.id_plata">
                    <td>{{ plata.data_plata }}</td>
                    <td class="text-end">{{
                            parseFloat(plata.plata).toLocaleString('ro-RO', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })
                        }}
                    </td>
                    <td class="dnp">
                        <a href="#" @click.prevent="stergeFactura('plata-intermediara', plata.id_plata)">Sterge</a>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td><strong>Total intermediar</strong></td>
                    <td class="text-end">{{
                            parseFloat(totalIntermediar).toLocaleString('ro-Ro', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })
                        }}
                    </td>
                </tr>
                </tfoot>
            </table>
        </td>
        <td v-else colspan="3"></td>

        <td colspan="3" v-if="(angajat.libere.length > 0 || Object.keys(angajat.libere).length > 0)">
            <p class="text-center m-0 p-0 text-danger">Zile neplatite</p>
            <table class="table table-sm text-danger">
                <thead>
                <tr>
                    <th>Data</th>
                    <th>Motiv</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="liber in angajat.libere" :key="liber.id_liber" :data-zi-libera="liber.id_liber">
                    <td>{{ liber.data_liber }}</td>
                    <td>{{ liber.motiv }}</td>
                    <td><a href="#" @click.prevent="stergeFactura('libere-angajati', liber.id_liber)">Sterge</a></td>
                </tr>
                </tbody>
            </table>
        </td>
        <td v-else colspan="3"></td>
    </tr>
    <tr class="separator">
        <td colspan="12" class="bg-info">

        </td>
    </tr>

</template>

<script>
import stergeFactura from "@/minxins/stergeFactura";

export default {
    name: "ArataAngajat",
    props: ['angajat', 'index', 'platit'],
    mixins: [stergeFactura],
    data() {
        return {
            dNone: false
        }
    },
    computed: {
        totalIntermediar() {
            let ti = 0;
            if (Object.keys(this.angajat.plati).length > 0) {
                for (const key in this.angajat.plati) {
                    ti = parseFloat(ti) + parseFloat(this.angajat.plati[key].plata);
                }
            }
            return ti;
        },
        restDePlataZi() {
            return parseFloat(this.angajat.de_plata_zi) - parseFloat(this.totalIntermediar);
        },
        restDePlataSeptembrie() {
            return parseFloat(this.angajat.de_plata_septembrie) - parseFloat(this.totalIntermediar);
        }
    },
    methods: {
        tipareste() {
            let deTiparit = document.querySelectorAll('tr[data-angajat="' + this.angajat.id + '"]'),
                winPrint = window.open('', '', 'left=0, top=0, width=1280, height=300, toolbars=0, scrollbars=0, address=0, status=0'),
                write = '';
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }
            write = `<!doctype html><html lang="ro"><head>${stylesHtml}</head><body>`
            write += `<table class="table table-sm table-bordered mt-3">`;
            write += `<thead>
                        <tr>
<!--                            <th></th>-->
                            <th>Prenume</th>
                            <th>Nume</th>
                            <th nowrap>Data inceput</th>
                            <th nowrap>Data sfarsit</th>
                            <th nowrap>Zile calendaristice</th>
                            <th nowrap>Zile lucrate</th>
                            <th nowrap>Zile neplatite</th>
                            <th nowrap>Plata pe zi</th>
                            <th nowrap>La zi</th>
                            <th nowrap>La final</th>
                            <th class="dnp"></th>
                        </tr>
                    </thead>`;
            write += `<tbody>`;
            for (const i in deTiparit) {
                if (typeof deTiparit[i].innerHTML !== "undefined") {
                    write += '<tr>' + deTiparit[i].innerHTML + '</tr>';
                }
            }
            write += `</tbody>`;
            write += `</table>`;
            write += `</body></html>`;

            for (let j = 0; j < 2; j++) { //2 exemplare
                winPrint.document.write(write);
            }
            winPrint.document.close();
            winPrint.focus();
            winPrint.print();
            winPrint.close();
        }
    },
    emits: ['zi-libera']
}
</script>

<style scoped lang="scss">
@media print {
    table {
        font-size: 12px;
    }

    .dnp {
        display: none;
        visibility: hidden;
    }

    .separator {
        border-left-color: #ffffff;
        border-right: 0;
    }
}
</style>