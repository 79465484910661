<template>
    <div class="row">
        <div class="col-lg-6">
            <form action="#" method="post" @submit.prevent="adaugaPlata">
                <p>
                    <strong>Angajat: </strong> {{ infoAngajat.fname + ' ' + infoAngajat.lname }}<br>
                    <strong>Data inceput: </strong> {{ infoAngajat.data_inceput }}<br>
                    <strong>Data sfarsit: </strong> {{ infoAngajat.data_sfarsit }}<br>
                    <strong>Plata / zi: </strong> {{ infoAngajat.plata }}<br>
                    <strong>Zile lucrate: </strong> {{ infoAngajat.zile_lucrate }}<br>
                </p>

                <div class="mb-3">
                    <label for="plata">Plata noua</label>
                    <input required type="number" name="plata" id="plata" class="form-control" ref="plata" step=".01">
                </div>

                <div class="mb-3">
                    <label for="data">Data</label>
                    <input type="date" name="data" id="data" class="form-control" ref="data"
                           v-model="dataCurenta">
                </div>

                <p v-if="displayAnswer"
                   :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                    {{ answer }}</p>
                <button type="submit" class="btn btn-primary" :disabled="buttonDisabled">Adauga plata</button>
            </form>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/config";

export default {
    name: "AdaugaPlata",
    data() {
        return {
            infoAngajat: [],
            dataCurenta: new Date().toISOString().split('T')[0],
            displayAnswer: false,
            alertSuccess: false,
            alertDanger: false,
            answer: '',
            buttonDisabled: false,
        }
    },
    mounted() {
        this.getInfoAngajat();
    },
    methods: {
        getInfoAngajat() {
            fetch(API_URL + '/get-angajat-info.php', {
                method: 'post',
                body: JSON.stringify({'angajat': this.$parent.idAngajat}),
                headers: {
                    'Content-type': 'application/json',
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.infoAngajat = answer.msg;
                    }
                });
        },
        async adaugaPlata() {
            let formBody = {
                idAngajat: this.$parent.idAngajat,
                plata: this.$refs.plata.value,
                data: this.$refs.data.value,
            };

            if (new Date(formBody.data) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                await fetch(API_URL + '/adauga-plata-angajat.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;
                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;
                    })
            }

        }
    }
}
</script>

<style scoped>

</style>