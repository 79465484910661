<template>
    <div class="row">
        <div class="col-lg-12">
            <h2 class="h4">Centralizator restante clienti</h2>
            <div class="row">
                <div class="col-lg-4" v-for="tip in ['aviz', 'factura']" :key="tip">
                    <h2 class="h5">{{ tip }}</h2>
                    <table class="table table-sm bg-success text-white">
                        <tr v-for="(rest, index) in restDePlata[tip]" :key="index">
                            <td class="ps-1">
                                <a class="text-white text-decoration-none d-block"
                                   :href="'/facturiIncasate/' + rest.id">
                                    {{ rest.nume }}
                                </a>
                            </td>
                            <td class="text-end pe-1">{{
                                    rest.dePlata.toLocaleString('ro-RO', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })
                                }}
                            </td>
                        </tr>
                        <tr class="bg-danger">
                            <td>Total</td>
                            <td class="text-end">{{
                                    parseFloat(totalDePlata[tip]).toLocaleString('ro-Ro', {
                                        style: 'decimal',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    })
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-lg-4">
                    <h2 class="h4">De plata</h2>
                    <table class="table table-sm bg-success text-white">
                        <tr v-for="(rest, index) in mainTotal" :key="index">
                            <td class="ps-1">
                                <a class="text-white text-decoration-none d-block"
                                   :href="'/facturiIncasate/' + rest.id">
                                    {{ rest.nume }}
                                </a>
                            </td>
                            <td class="text-end pe-1">{{
                                    rest.dePlata.toLocaleString('ro-RO', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 bg-danger text-white text-end">

                    {{
                        parseFloat(totalDePlata['gt']).toLocaleString('ro-Ro', {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })
                    }}
                </div>

            </div>
        </div>
    </div>

    <!--    <div class="row">
            <div class="col-lg-12">
                <h2>Lista facturi</h2>

                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th>Furnizor</th>
                        <th>Beneficiar</th>
                        <th>Document</th>
                        <th>Numar</th>
                        <th>Data</th>
                        <th>Valoare</th>
                        <th>Observatii</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr is="vue:afiseaza-factura"
                        v-for="factura in listaFacturi"
                        :key="factura.id"
                        :factura="factura"
                        :platit="true"
                        @rest-plata="restPlata">
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>-->
</template>

<script>
import {API_URL} from '@/config';
// import AfiseazaFactura from "@/components/de_incasat/AfiseazaFactura";
import {useAuthStore} from "@/store";
import router from "@/router";

export default {
    name: "FacturiPlatite",
    components: {
        // AfiseazaFactura
    },
    emits: [
        'plateste-factura',
    ],
    data() {
        return {
            listaFacturi: [],
            authStore: useAuthStore(),
            // restDePlata: {},
        }
    },
    computed: {
        totalDePlata() {
            let tp = {
                    'aviz': 0,
                    'factura': 0,
                    'gt': 0,
                },
                tipuri = [
                    'aviz',
                    'factura'
                ];
            for (const tip in tipuri) {
                for (const index in this.restDePlata[tipuri[tip]]) {
                    tp[tipuri[tip]] = parseFloat(tp[tipuri[tip]]) + parseFloat(this.restDePlata[tipuri[tip]][index].dePlata);
                }
            }

            tp.gt = parseFloat(tp['aviz']) + parseFloat(tp['factura']);
            return tp;
        },
        restDePlata() {
            let ret = {
                'aviz': {},
                'factura': {}
            };
            for (const key in this.listaFacturi) {

                if (!Object.prototype.hasOwnProperty.call(ret[this.listaFacturi[key].tip_document], this.listaFacturi[key].id_beneficiar)) {
                    ret[this.listaFacturi[key].tip_document][this.listaFacturi[key].id_beneficiar] = {
                        nume: this.listaFacturi[key].beneficiar,
                        dePlata: this.listaFacturi[key].valoare_factura,
                        id: this.listaFacturi[key].id_beneficiar

                    }
                } else {
                    ret[this.listaFacturi[key].tip_document][this.listaFacturi[key].id_beneficiar].dePlata = parseFloat(ret[this.listaFacturi[key].tip_document][this.listaFacturi[key].id_beneficiar].dePlata) + parseFloat(this.listaFacturi[key].valoare_factura)
                }
            }

            return ret;
        },
        mainTotal() {
            let ret = {};
            for (const key in this.listaFacturi) {

                if (!Object.prototype.hasOwnProperty.call(ret, this.listaFacturi[key].id_beneficiar)) {
                    ret[this.listaFacturi[key].id_beneficiar] = {
                        nume: this.listaFacturi[key].beneficiar,
                        dePlata: this.listaFacturi[key].valoare_factura,
                        id: this.listaFacturi[key].id_beneficiar

                    }
                } else {
                    ret[this.listaFacturi[key].id_beneficiar].dePlata = parseFloat(ret[this.listaFacturi[key].id_beneficiar].dePlata) + parseFloat(this.listaFacturi[key].valoare_factura)
                }

            }
            return ret;
        }
    },
    mounted() {
        this.getFacturi();
    },
    methods: {
        getFacturi() {
            let headers = {};
            if (this.authStore.username.token) {
                headers.Token = this.authStore.username.token;
            }
            fetch(API_URL + '/facturi.php?arata=platite', {
                headers: headers
            })
                .then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.listaFacturi = answer.msg;
                    } else {
                        if (answer.msg.toLowerCase().includes('token')) {
                            this.authStore.username = null;
                            localStorage.removeItem('username');
                            router.push('/login');
                        }
                    }
                })
                .catch(e => console.log(e));
        },
        platesteFactura(idFactura) {
            this.$emit('plateste-factura', idFactura);
        },
    }
}
</script>

<style scoped>

</style>