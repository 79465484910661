<template>

    <header v-if="this.authStore.username">

        <ul class="nav nav-tabs justify-content-center mainNav"
            style="">
            <li class="nav-item">
                <router-link :class="{'nav-link': true}" to="/">Facturi de incasat</router-link>

            </li>
            <li class="nav-item">
                <router-link :class="{'nav-link': true}" to="/facturi-furnizori">Facturi de platit</router-link>

            </li>
            <li class="nav-item">
                <router-link :class="{'nav-link': true}" to="/pontaj">Pontaj</router-link>
            </li>

<!--            <li class="nav-item">
                <select name="filtru_an" id="filtru_an" class="form-select">
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                </select>
            </li>-->
        </ul>
    </header>

    <router-view />


    <footer>

    </footer>
</template>

<script>
import {API_URL} from "@/config";
import {useAuthStore} from '@/store'

// const authStore = useAuthStore();

export default {
    data() {
        return {
            authStore: useAuthStore(),
        }
    },
    methods: {
        getBeneficiari() {
            fetch(API_URL + '/beneficiari-facturi.php')
                .then(ans => ans.json())
                .then(ans => {
                    if (ans.error === false) {
                        for (let beneficiar of ans.msg) {
                            this.listaBeneficiari.push({
                                id: beneficiar.id,
                                denumire: beneficiar.denumire
                            });
                        }
                    }
                })
                .catch(e => console.log(e));
        },
    }
}
</script>


<style lang="scss">
footer {
    padding: 1rem;
}

ul.mainNav {
    //margin-top: 10px;
    background-color: #ccc;
    padding: 10px 0;
    a {
        //border: 1px solid #000000;
        margin-left: 5px;
        color: #000;

        &:hover {
            color: #000;
        }

        &.active {
            background-color: #ff0000;
            color: #ffffff;
            border-radius: 5px;
        }
    }
}

ul.secondaryNav {
    background-color: #000000;
    padding: 10px 0;

    li.nav-item {
        &:hover {
            border: 0;
        }

        a {
            color: #ffffff;
            //border: 1px solid #000000;
            margin-left: 5px;

            &:hover, &:focus, &:active {
                border: none !important;
            }

            &.active {
                background-color: #ffffff;
                color: #000000;
                //border-color: #0000ff;
            }
        }
    }
}

.table>:not(caption)>*>* {
    background-color: transparent!important;
}
</style>