<template>
    <div class="container-fluid">
        <article class="facturi-incasate">

            <div class="row">
                <div class="col-lg-12">
                    <h2>Facturi incasate</h2>
                </div>
            </div>
            <div class="row">
                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th>Furnizor</th>
                        <th>Beneficiar</th>
                        <th>Document</th>
                        <th>Numar</th>
                        <th>Data</th>
                        <th>Valoare</th>
                        <th>Observatii</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr is="vue:facturi-incasate" v-for="factura in facturiIncasate" :key="factura.id"
                        :factura="factura"
                        @rest-plata="restPlata">
                        {{ factura }}
                    </tr>
                    </tbody>
                </table>
            </div>
        </article>

        <!--        <article>
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>Facturi platite</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-sm">
                                <thead>
                                <tr>
                                    <th nowrap>Furnizor</th>
                                    <th nowrap>Beneficiar</th>
                                    <th nowrap>Document</th>
                                    <th nowrap>Numar document</th>
                                    <th nowrap>Data</th>
                                    <th nowrap>Valoare</th>
                                    <th nowrap>Detalii</th>
                                    <th nowrap></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr is="vue:facturi-platite" v-for="factura in facturiPlatite" :key="factura.id"
                                    :factura="factura" @rest-plata="restPlata"></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </article>-->

        <div class="row">
            <div class="col-lg-12">
                <h2>Angajati platiti</h2>
            </div>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/config";
import router from "@/router";
import {useAuthStore} from "@/store";
import FacturiIncasate from "@/components/de_incasat/AfiseazaFactura";
// import FacturiPlatite from "@/components/de_plata/ListeazaFacturiFurnizori"

export default {
    name: "PlatiFinalizateView",
    components: {
        FacturiIncasate,
        // FacturiPlatite
    },
    data() {
        return {
            authStore: useAuthStore(),
            facturiIncasate: [],
            facturiPlatite: [],
            angajati: [],
        }
    },
    mounted() {
        this.getFacturiIncasate()
        // this.getFacturiPlatite()
    },
    methods: {
        getFacturiIncasate() {
            let headers = {};
            if (this.authStore.username.token) {
                headers.Token = this.authStore.username.token;
            }
            fetch(API_URL + '/facturi.php?arata=platite', {
                headers: headers
            })
                .then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.facturiIncasate = answer.msg;
                    } else {
                        if (answer.msg.toLowerCase().includes('token')) {
                            this.authStore.username = null;
                            localStorage.removeItem('username');
                            router.push('/login');
                        }
                    }
                })
                .catch(e => console.log(e));
        },
        getFacturiPlatite() {
            let headers = {};
            if (this.authStore.username.token) {
                headers.Token = this.authStore.username.token;
            }
            fetch(API_URL + '/lista-facturi-furnizori.php?arata=platite', {
                headers: headers
            })
                .then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.facturiPlatite = answer.msg;
                    } else {
                        if (answer.msg.toLowerCase().includes('token')) {
                            this.authStore.username = null;
                            localStorage.removeItem('username');
                            router.push('/login');
                        }
                    }
                })
                .catch(e => console.log(e));
        },
    }
}
</script>

<style scoped>

</style>