<template>
    <div class="col-lg-6">
        <form action="#" method="post" @submit.prevent="adaugaFactura">

            <h2 class="h3">Adauga factura</h2>

            <div class="mb-3">
                <label for="emitator" class="form-label">Factura emisa de</label>
                <select name="emitator" id="emitator" class="form-select" ref="emitator">
                    <option v-for="firma in listaFirme" :key="firma.id" :value="firma.id">{{ firma.denumire }}
                    </option>
                </select>
            </div>

            <div class="mb-3">
                <label for="beneficiar" class="form-label">Beneficiar</label>
                <input class="form-control" type="text" name="beneficiar" id="beneficiar" ref="beneficiar"
                       list="beneficiari" required>
                <datalist id="beneficiari">
                    <option v-for="beneficiar in listaBeneficiari" :key="beneficiar.id"
                            :value="beneficiar.denumire"></option>
                </datalist>
            </div>

            <div class="mb-3">
                <label for="tip_document">Tip document</label>
                <select name="tipFactura" id="tip_document" ref="tip_document" required class="form-select">
                    <option value=""></option>
                    <option value="aviz">Aviz</option>
                    <option value="factura">Factura</option>
                </select>

            </div>

            <div class="mb-3">
                <label for="nr_factura" class="form-label">Numar document</label>
                <input required class="form-control" type="text" name="nr_factura" id="nr_factura" ref="nr_factura">
            </div>

            <div class="mb-3">
                <label for="data_factura" class="form-label">Data factura</label>
                <input required class="form-control" type="date" name="data_factura" id="data_factura"
                       ref="data_factura">
            </div>

            <div class="mb-3">
                <label for="valoare_factura" class="form-label">Valoare factura</label>
                <input required class="form-control" type="number" name="valoare_factura" id="valoare_factura"
                       ref="valoare_factura" step=".01" min="0">
            </div>

            <div class="mb-3">
                <label for="detalii_factura" class="form-label">Detalii factura</label>
                <textarea class="form-control" name="detalii_factura" id="detalii_factura" cols="30" rows="3"
                          ref="detalii_factura"></textarea>
            </div>

            <p v-if="displayAnswer"
               :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                {{ answer }}</p>
            <button class="btn btn-primary">Salveaza</button>
        </form>
    </div>
</template>

<script>
import {API_URL} from '@/config';
import listaFirme from "@/minxins/listaFirme";

export default {
    name: "AdaugaFactura",
    mixins: [
        listaFirme,
    ],
    data() {
        return {
            listaFirme: [],
            listaBeneficiari: [],
            alertSuccess: false,
            alertDanger: false,
            displayAnswer: false,
            answer: '',
        }
    },
    mounted() {
        this.getListaFirme();
        // this.getBeneficiari();
        this.getBeneficiari();
    },
    methods: {
        getBeneficiari() {
            fetch(API_URL + '/beneficiari-facturi.php')
                .then(ans => ans.json())
                .then(ans => {
                    if (ans.error === false) {
                        for (let beneficiar of ans.msg) {
                            this.listaBeneficiari.push({
                                id: beneficiar.id,
                                denumire: beneficiar.denumire
                            });
                        }
                    }
                })
                .catch(e => console.log(e));
        },
        adaugaFactura() {
            let formBody = {
                emitator: this.$refs.emitator.value,
                beneficiar: this.$refs.beneficiar.value,
                nr_factura: this.$refs.nr_factura.value,
                data_factura: this.$refs.data_factura.value,
                valoare_factura: this.$refs.valoare_factura.value,
                detalii_factura: this.$refs.detalii_factura.value,
                tip_document: this.$refs.tip_document.value,
            };
            if (new Date(formBody.data_factura) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/adauga-factura.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            let t = this.listaBeneficiari.find(beneficiar => beneficiar.id === answer.beneficiar.idBeneficiar);
                            if (typeof t === 'undefined') {
                                this.listaBeneficiari.push({
                                    id: answer.beneficiar.idBeneficiar,
                                    denumire: answer.beneficiar.denumire
                                });
                            }
                            this.alertSuccess = true;
                            this.alertDanger = false;

                            this.$refs.emitator.value = '';
                            this.$refs.beneficiar.value = '';
                            this.$refs.nr_factura.value = '';
                            this.$refs.data_factura.value = '';
                            this.$refs.valoare_factura.value = '';
                            this.$refs.detalii_factura.value = '';
                            this.$refs.tip_document.value = '';

                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;

                        setTimeout(() => {
                            this.displayAnswer = false;
                        }, 3000)
                    })
                    .catch(e => console.log(e));
            }
        }
    }
}
</script>

<style scoped>

</style>