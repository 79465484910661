<template>
    <div class="row">
        <div class="col-lg-6">
            <form action="#" method="post" @submit.prevent="modificaAngajat">
                <h2 class="h3">Editeaza angajat</h2>

                <div class="mb-3">
                    <label for="fname">Prenume</label>
                    <input type="text" name="fname" class="form-control" id="fname" ref="fname"
                           :value="infoAngajat.fname">
                </div>
                <div class="mb-3">
                    <label for="lname">Nume</label>
                    <input type="text" name="lname" id="lname" class="form-control" ref="lname" required
                           :value="infoAngajat.lname">
                </div>

                <div class="mb-3">
                    <label for="data_inceput">Data inceput</label>
                    <input type="date" name="data_inceput" id="data_inceput" class="form-control" ref="data_inceput"
                           required :value="infoAngajat.data_inceput">
                </div>

                <div class="mb-3">
                    <label for="data_sfarsit">Data sfarsit</label>
                    <div class="input-group">

                        <input type="date" name="data_sfarsit" id="data_sfarsit" class="form-control" ref="data_sfarsit"
                               :value="infoAngajat.data_sfarsit">
                        <span class="input-group-text">
                            <a href="#" @click.prevent="golesteZiSfarsit">goleste</a>
                        </span>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="plata">Suma plata / zi</label>
                    <input type="number" name="plata" id="plata" class="form-control" ref="plata" step=".01" required
                           :value="infoAngajat.plata">
                </div>

                <p v-if="displayAnswer"
                   :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                    {{ answer }}</p>
                <button type="submit" class="btn btn-primary" :disabled="buttonDisabled">Modifica</button>
            </form>
        </div>


        <div class="col-lg-6">
            <h2 class="h4">Adauga zile neplatite</h2>
            <p class="text-muted">Pentru a adauga doar ziua curenta, selecteaza numai data de inceput</p>
            <form action="#" @submit.prevent="adaugaZiLibera">
                <div class="mb-3">
                    <label for="inceput_liber">Data inceput</label>
                    <input type="date" name="data_inceput_liber" id="inceput_liber" class="form-control"
                           ref="inceput_liber" v-model="azi">
                </div>

                <div class="mb-3">
                    <label for="sfarsit_liber">Data sfarsit</label>
                    <input type="date" name="data_sfarsit_liber" id="sfarsit_liber" class="form-control"
                           ref="sfarsit_liber">
                </div>

                <div class="mb-3">
                    <label for="motiv_liber">Motiv</label>
                    <textarea name="motiv_liber" id="motiv_liber" cols="30" rows="5" class="form-control"
                              ref="motiv_liber"></textarea>
                </div>

                <p v-if="displayAnswerLiber"
                   :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                    {{ answer }}</p>
                <button type="submit" class="btn btn-primary">Adauga liber</button>
            </form>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/config";

export default {
    name: "EditeazaAngajat",
    data() {
        return {
            infoAngajat: [],
            listaAngajati: [],
            alertSuccess: false,
            alertDanger: false,
            displayAnswer: false,
            answer: '',
            buttonDisabled: false,
            azi: new Date().toISOString().split('T')[0],
        }
    },
    mounted() {
        this.getInfoAngajat();
    },
    methods: {
        golesteZiSfarsit() {
            this.$refs.data_sfarsit.value = '';
        },
        getInfoAngajat() {
            fetch(API_URL + '/get-angajat-info.php', {
                method: 'post',
                body: JSON.stringify({'angajat': this.$parent.idAngajat}),
                headers: {
                    'Content-type': 'application/json',
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.infoAngajat = answer.msg;
                    }
                });
        },
        modificaAngajat() {
            let formBody = {
                fname: this.$refs.fname.value,
                lname: this.$refs.lname.value,
                data_inceput: this.$refs.data_inceput.value,
                data_sfarsit: this.$refs.data_sfarsit.value,
                plata: this.$refs.plata.value,
                id: this.infoAngajat.id,
            }

            if (new Date(formBody.data_inceput) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/modifica-angajat.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {

                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;

                            for (const key in formBody) {
                                if (key !== 'id') {
                                    this.infoAngajat[key] = formBody[key];
                                }
                            }

                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;

                    }).catch(e => console.log(e));
            }
        },

        adaugaZiLibera() {
            if (confirm('Adaugi zi libera pentru acest angajat?')) {
                let formBody = {
                    data_inceput: this.$refs.inceput_liber.value,
                    data_sfarsit: this.$refs.sfarsit_liber.value,
                    angajat: this.infoAngajat.id,
                    motiv: this.$refs.motiv_liber.value,
                }

                fetch(API_URL + '/adauga-zi-libera.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        console.log(answer);
                    });
            }
        }
    }
}
</script>

<style scoped>

</style>