<template>
    <div class="modal" tabindex="-1" id="modalAdaugaPlata">
        <div class="modal-dialog">
            <div class="modal-content">
                <form action="#" method="post" @submit.prevent="adaugaPlata">
                    <div class="modal-header">
                        <h5 class="modal-title">Adauga plata furnizor</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="$parent.detaliiFactura">
                        <input type="hidden" name="id_factura" :value="$parent.detaliiFactura['id']"
                               ref="id_factura">
                        <div class="mb-3"> <!--{{ $parent.detaliiFactura }}-->
                            Factura <strong>{{ $parent.detaliiFactura['numar_document'] }}</strong> din
                            <strong>{{ $parent.detaliiFactura['data_emitere'] }}</strong> <br>
                            Emisa de: {{ $parent.detaliiFactura['furnizor'] }}<br>
                            Beneficiar: {{ $parent.detaliiFactura['beneficiar'] }}<br>
                            Valoare factura: <strong>{{
                                    parseFloat($parent.detaliiFactura['valoare']).toLocaleString('ro-Ro', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })
                                }}</strong><br>
                            Detalii: {{ $parent.detaliiFactura['detalii'] }}
                        </div>

                        <div class="mb-3">
                            <label for="tip_document">Tip document</label>
                            <select name="tip_document" id="tip_document" class="form-control" ref="tip_document"
                                    required>
                                <option value="op">OP</option>
                                <option value="chitanta">Chitanta</option>
                                <option value="cash">Cash</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="numar_document">Numar document</label>
                            <input type="text" name="numar_document" id="numar_document" class="form-control"
                                   ref="numar_document" required :value="Math.floor(Date.now() / 1000)">
                        </div>

                        <div class="mb-3">
                            <label for="data_incasare">Data plata</label>
                            <input type="date" name="data_plata" id="data_plata" class="form-control"
                                   ref="data_plata" required>
                        </div>

                        <div class="mb-3">
                            <label for="valoare_plata">Valoare plata</label>
                            <input type="number" name="valoare_plata" id="valoare_plata" class="form-control"
                                   ref="valoare_plata" step=".01" required>
                        </div>

                        <div class="mb-3">
                            <label for="detalii_plata">Detalii plata</label>
                            <textarea name="detalii_plata" id="detalii_plata" cols="30" rows="3"
                                      ref="detalii_plata" class="form-control"></textarea>
                        </div>

                        <p v-if="displayAnswer"
                           :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                            {{ answer }}</p>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button :disabled="buttonDisabled" type="submit" class="btn btn-primary">Adauga plata</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import {API_URL} from "@/config";
import {ref} from 'vue'
import {useAuthStore} from "@/store";

export default {
    name: "AdaugaPlata",
    setup() {
        const displayAnswer = ref(false),
            alertSuccess = ref(false),
            alertDanger = ref(false),
            answer = ref(),
            buttonDisabled = ref(false),
            authStore = useAuthStore()

        return {
            displayAnswer,
            alertSuccess,
            alertDanger,
            answer,
            buttonDisabled,
            authStore,
        }
    },
    methods: {

        adaugaPlata() {

            let body = {
                id_factura: this.$refs.id_factura.value,
                numar_document: this.$refs.numar_document.value,
                tip_document: this.$refs.tip_document.value,
                data_plata: this.$refs.data_plata.value,
                valoare_plata: this.$refs.valoare_plata.value,
                detalii_plata: this.$refs.detalii_plata.value,
            };

            fetch(API_URL + '/adauga-plata-furnizori.php', {
                method: 'post',
                body: JSON.stringify(body),
                headers: {
                    'Content-type': 'application/json',
                    'Token': this.authStore.username.token
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.alertSuccess = true;
                        this.alertDanger = false;
                        this.buttonDisabled = true;

                        //adauga la plati
                        let plata = {
                            'data_plata': answer.info.data_plata,
                            'tip_document': answer.info.tip_document,
                            'numar_document': answer.info.numar_document,
                            'valoare_plata': answer.info.valoare,
                            'id_plata': answer.info.insert_id,
                        }
                        this.$parent.infoFurnizor[this.$parent.idFactura].plati.push(plata);


                    } else {
                        this.alertSuccess = false;
                        this.alertDanger = true;
                    }
                    this.answer = answer.msg;
                    this.displayAnswer = true;
                })
                .catch(e => console.log(e));
        }
    }
}
</script>


<style scoped>

</style>