<template>
    <div class="modal" tabindex="-1" id="modalAdaugaPlataAngajat">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Adauga plata</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form action="#" method="post" @submit.prevent="adaugaPlata">
                    <div class="modal-body">
                        <p v-if="$parent.listaAngajati">
                            <strong>Angajat: </strong>
                            {{
                                $parent.listaAngajati[$parent.idAngajat].fname + ' ' + $parent.listaAngajati[$parent.idAngajat].lname
                            }}<br>
                            <strong>Data inceput: </strong> {{
                                $parent.listaAngajati[$parent.idAngajat].data_inceput
                            }}<br>
                            <strong>Data sfarsit: </strong> {{
                                $parent.listaAngajati[$parent.idAngajat].data_sfarsit
                            }}<br>
                            <strong>Plata / zi: </strong> {{ $parent.listaAngajati[$parent.idAngajat].pe_zi }}<br>
                            <strong>Zile lucrate: </strong> {{
                                $parent.listaAngajati[$parent.idAngajat].zile_lucrate
                            }}<br>
                        </p>

                        <div class="mb-3">
                            <label for="plata">Plata noua</label>
                            <input required type="number" name="plata" id="plata" class="form-control" ref="plata"
                                   step=".01">
                        </div>

                        <div class="mb-3">
                            <label for="data">Data</label>
                            <input type="date" name="data" id="data" class="form-control" ref="data"
                                   v-model="dataCurenta">
                        </div>

                        <p v-if="displayAnswer"
                           :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                            {{ answer }}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary" :disabled="buttonDisabled">Adauga plata</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import {API_URL} from "@/config";

export default {
    name: "AdaugaPlata",
    data() {
        return {
            dataCurenta: new Date().toISOString().split('T')[0],
            displayAnswer: false,
            alertSuccess: false,
            alertDanger: false,
            answer: '',
            buttonDisabled: false,
        }
    },
    methods: {
        adaugaPlata() {
            const formBody = {
                idAngajat: this.$parent.idAngajat,
                plata: this.$refs.plata.value,
                data: this.$refs.data.value,
            };

            if (new Date(formBody.data) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/adauga-plata-angajat.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;

                            let id = answer.id
                            this.$parent.listaAngajati[this.$parent.idAngajat].plati[id] = {
                                id_plata: id,
                                data_plata: formBody.data,
                                plata: formBody.plata
                            }
                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;
                    })
            }

        }
    }
}
</script>

<style scoped>

</style>