<template>
    <tr :data-factura="factura.id"
        :class="{'bg-danger': bgDanger, 'text-white': textWhite, 'bg-success': bgSuccess}"
        :id="'tr_' + factura.id">
        <td nowrap>{{ factura.furnizor }}</td>
        <td nowrap>{{ factura.beneficiar }}</td>
        <td nowrap>{{ factura.tip_document }}</td>
        <td nowrap>{{ factura.numar_factura }}</td>
        <td nowrap>{{ factura.data_factura }}</td>
        <td nowrap>{{
                parseFloat(factura.valoare_factura).toLocaleString('ro-RO', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }}
        </td>
        <td nowrap>{{ factura.detalii_factura }}</td>
        <td nowrap>
            <div v-if="!this.platit">
                <a :class="{'text-white': textWhite}" href="#" @click.prevent="$parent.platesteFactura(factura.id)">adauga
                    incasare</a> /
                <a :class="{'text-white': textWhite}" href="#"
                   @click.prevent="stergeFactura('incasari', factura.id)">sterge</a>
            </div>

            <div v-else>
                <a :class="{'text-white': textWhite}" href="#"
                   @click.prevent="stergeFactura('incasari', factura.id)">sterge</a>
            </div>
        </td>
    </tr>
    <tr :data-factura="factura.id">
        <td colspan="8" class="text-end">Rest plata: {{
                parseFloat(restPlata).toLocaleString('ro-RO', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }}
        </td>
    </tr>
    <tr v-if="factura.plati.length > 0" :data-factura="factura.id">
        <td colspan="4">
            <table class="table table-sm table-bordered plati text-success">
                <thead>
                <tr>
                    <th>Data plata</th>
                    <th>Tip document</th>
                    <th>Numar document</th>
                    <th>Valoare platita</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="plata in factura.plati" :key="plata.id_plata" :data-plata="plata.id_plata">
                    <td class="text-end">{{ plata.data_plata }}</td>
                    <td class="text-end">{{ plata.tip_document }}</td>
                    <td class="text-end">{{ plata.numar_document }}</td>
                    <td class="text-end">{{
                            parseFloat(plata.valoare_plata).toLocaleString('ro-RO', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })
                        }}
                    </td>
                    <td>
                        <a href="#" @click.prevent="stergeFactura('factura-platita', plata.id_plata)">Sterge</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
        <td colspan="4"></td>
    </tr>

</template>

<script>
import stergeFactura from "@/minxins/stergeFactura";

export default {
    name: "AfiseazaFactura",
    props: ['factura', 'platit'],
    // emits: ['rest-plata'],
    data() {
        return {
            bgDanger: false,
            textWhite: false,
            bgSuccess: false,
        }
    },
    mixins: [stergeFactura],
    computed: {
        restPlata() {
            const totalFactura = parseFloat(this.factura.valoare_factura);
            let platit = 0;
            if (this.factura.plati.length > 0) {
                for (let i = 0; i < this.factura.plati.length; i++) {
                    platit = parseFloat(platit) + parseFloat(this.factura.plati[i].valoare_plata);
                }
            }
            let restPlata = (parseFloat(totalFactura) - parseFloat(platit)).toFixed(2);
            if (parseFloat(restPlata) === 0) {
                this.setSuccessBackground();
                this.setWhiteText();
            } else {
                this.setDangerBackground();
                this.setWhiteText();
            }
            // this.$emit('rest-plata', this.factura.id_beneficiar, this.factura.beneficiar, restPlata);
            return restPlata;
        }
    },
    methods: {
        setSuccessBackground() {
            this.bgSuccess = true;
        },
        setDangerBackground() {
            this.bgDanger = true;
        },
        setWhiteText() {
            this.textWhite = true;
        }
    }
}
</script>

<style scoped>
.platita {
}
</style>