import {createRouter, createWebHistory} from 'vue-router';
// import FacturiView from "@/views/FacturiView";
// import FacturiFurnizoriView from "@/views/FacturiFurnizoriView";
import FacturiDeIncasatView from "@/views/FacturiDeIncasatView";
import FacturiDePlataFurnizoriView from "@/views/FacturiDePlataFurnizoriView";
import PontajView from "@/views/PontajView";
import LoginView from "@/views/LoginView";
import {useAuthStore} from "@/store";
import PlatiFinalizateView from "@/views/PlatiFinalizateView";
import DetaliiFurnizorView from "@/views/DetaliiFurnizorView";
import FacturiIncasate from "@/components/de_incasat/v2/AfiseazaFactura"
import InfoAngajat from "@/components/angajati/v2/InfoAngajat";

const router = createRouter({
    routes: [
        {
            path: '/',
            name: 'facturi',
            component: FacturiDeIncasatView
        }, {
            path: '/facturi-furnizori',
            name: 'facturiFurnizori',
            component: FacturiDePlataFurnizoriView
        }, {
            path: '/pontaj',
            name: 'pontaj',
            component: PontajView
        }, {
            path: '/login',
            name: 'login',
            component: LoginView
        }, {
            path: '/plati-finalizate',
            name: 'platiFinalizate',
            component: PlatiFinalizateView
        }, {
            path: '/furnizor/:id',
            name: 'detaliiFurnizor',
            component: DetaliiFurnizorView,
        }, {
            path: '/facturiIncasate/:id',
            name: 'facturiIncasate',
            component: FacturiIncasate,
        }, {
            path: '/angajat/:id',
            name: 'informatiiAngajat',
            component: InfoAngajat,
        }
    ],
    history: createWebHistory(),
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
});

router.beforeEach((to) => {
    const publicPages = ['/login'],
        authRequired = !publicPages.includes(to.path),
        auth = useAuthStore();

    if (authRequired && !auth.username) {
        return '/login';
    }
});

export default router;