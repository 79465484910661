import {API_URL} from "@/config";

export default {
    methods: {
        getListaFirme() {
            fetch(API_URL + '/lista-firme.php')
                .then(ans => ans.json())
                .then((ans) => {
                    if (ans.error === false) {
                        for (let firma of ans.msg) {
                            this.listaFirme.push({
                                id: firma.id,
                                denumire: firma.denumire
                            });
                        }
                    }
                })
                .catch(e => console.log(e));
        },
    }
}