<template>
            <ul class="nav nav-tabs justify-content-center mainNav">
                <li class="nav-item"
                    v-for="destinatie in ['toate','bar','bucatarie','diverse','constructii']"
                    :key="destinatie">
                    <a :class="{active: selectedDestinatie === destinatie, 'nav-link': true}"
                       href="#"
                       @click="setSelectedDestinatie(destinatie)">{{ destinatie }}</a>
                </li>
            </ul>

</template>

<script>
export default {
    name: "FiltreFacturi",
    emits: ['schimbaFiltru'],
    data() {
        return {
            selectedDestinatie: 'toate',
        }
    },
    methods: {
        setSelectedDestinatie(destinatie) {
            this.selectedDestinatie = destinatie;
            this.$emit('schimbaFiltru', destinatie);
        },
    }
}
</script>

<style scoped>

</style>