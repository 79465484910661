<template>
    <div>
        <div class="col-lg-6">
            <form action="#" method="post" id="formAdaugaFacturaFurnizori" @submit.prevent="editeazaFacturaFurnizori">
                <h2 class="h3">Adauga factura furnizori</h2>

                <div class="mb-3">
                    <label for="destinatie">Destinatie</label>
                    <select name="destinatie" id="destinatie" ref="destinatie" required class="form-control">
                        <option value=""></option>
                        <option v-for="destinatie in destinatiiFacturi" :key="destinatie" :value="destinatie"
                                :selected="destinatie === infoFactura.destinatie">
                            {{ destinatie }}
                        </option>
                    </select>
                </div>

                <div class="mb-3">
                    <label for="tip_document">Tip Document</label>
                    <select name="tipFactura" id="tip_document" ref="tip_document" required class="form-select">
                        <option value=""></option>
                        <option value="aviz" :selected="'aviz' === infoFactura.tip_document">Aviz</option>
                        <option value="factura" :selected="'factura' === infoFactura.tip_document">Factura</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label for="numar_document">Numar document</label>
                    <input type="text" name="numar_document" id="numar_document" class="form-control"
                           ref="numar_document" required :value="infoFactura.numar_document">
                </div>

                <!--                <div class="mb-3">
                                    <label for="data_document">Data document</label>
                                    <input type="date" name="data_document" id="data_document" class="form-control" ref="data_document"
                                           required>
                                </div>-->

                <div class="mb-3">
                    <label for="valoare">Valoare</label>
                    <input type="number" name="valoare" id="valoare" class="form-control" ref="valoare" step=".01"
                           required :value="infoFactura.valoare">
                </div>

                <div class="mb-3">
                    <label for="detalii">Detalii</label>
                    <textarea name="detalii" id="detalii" cols="30" rows="3" class="form-control"
                              ref="detalii" :value="infoFactura.detalii"></textarea>
                </div>

                <p v-if="displayAnswer"
                   :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                    {{ answer }}</p>

                <button :disabled="buttonDisabled" class="btn btn-primary">Salveaza</button>
            </form>
        </div>
    </div>
</template>

<script>
import listaFirme from "@/minxins/listaFirme";
import {API_URL} from "@/config";
import {useAuthStore} from "@/store";
import router from "@/router";

export default {
    name: "EditeazaFacturaFurnizori",
    mixins: [listaFirme],
    data() {
        return {
            authStore: useAuthStore(),
            listaFirme: [],
            listaFurnizori: [],
            displayAnswer: false,
            alertSuccess: false,
            alertDanger: false,
            buttonDisabled: false,
            destinatiiFacturi: [
                'bar',
                'bucatarie',
                'diverse',
                'constructii',
            ],
            infoFactura: [],
        }
    },
    mounted() {
        this.getListaFirme();
        this.getFurnizori();
        this.getInfoFactura()
    },
    methods: {
        getFurnizori() {
            fetch(API_URL + '/get-lista-furnizori.php')
                .then(answer => answer.json())
                .then(answer => {
                    this.listaFurnizori = answer.msg;
                }).catch(e => console.log(e));
        },
        getInfoFactura() {
            fetch(API_URL + '/get-info-factura-furnizor.php', {
                method: 'post',
                body: JSON.stringify({
                    idFactura: this.$parent.idFactura
                }),
                headers: {
                    'content-type': 'application/json',
                    'Token': this.authStore.username.token
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.infoFactura = answer.msg;
                    } else {
                        if (answer.msg.toLowerCase().includes('token')) {
                            this.authStore.username = null;
                            localStorage.removeItem('username');
                            router.push('/login');
                        }
                    }
                }).catch(e => console.log(e));
        },
        editeazaFacturaFurnizori() {
            let formBody = {
                destinatie: this.$refs.destinatie.value,
                tip_document: this.$refs.tip_document.value,
                numar_document: this.$refs.numar_document.value,
                valoare: this.$refs.valoare.value,
                detalii: this.$refs.detalii.value,
                factura: this.$parent.idFactura,
            }

            fetch(API_URL + '/editeaza-factura-furnizor.php', {
                method: 'post',
                body: JSON.stringify(formBody),
                headers: {
                    'Content-Type': 'application/json',
                    'Token': this.authStore.username.token,
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {

                        this.alertSuccess = true;
                        this.alertDanger = false;
                        this.buttonDisabled = true;

                    } else {
                        this.alertSuccess = false;
                        this.alertDanger = true;
                    }
                    this.answer = answer.msg;
                    this.displayAnswer = true;
                })
                .catch(e => console.log(e));

        }
    }
}
</script>

<style scoped>

</style>