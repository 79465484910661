<template>
    <div class="modal" tabindex="-1" id="modalAdaugaProduse">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Produse</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form action="#" @submit.prevent="adaugaProduseFactura" v-if="$parent.produseFactura.info">
                        <div v-if="Object.keys($parent.produseFactura.produse).length > 0">
                            <p class="alert alert-warning" v-if="PretCalculat < $parent.produseFactura.info.valoare">
                                Valoarea facturii ({{ $parent.produseFactura.info.valoare }}) este mai mare decat pretul
                                total
                                calculat
                                ({{
                                    PretCalculat.toLocaleString('ro-RO', {
                                        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
                                    })
                                }})
                            </p>
                            <p class="alert alert-warning" v-if="PretCalculat > $parent.produseFactura.info.valoare">
                                Pretul calculat ({{
                                    PretCalculat.toLocaleString('ro-RO', {
                                        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
                                    })
                                }}) este mai mare decat valoarea facturii
                                ({{ $parent.produseFactura.info.valoare }})
                            </p>

                            <p class="alert alert-success" v-if="PretCalculat == $parent.produseFactura.info.valoare">
                                Pretul calculat ({{
                                    PretCalculat.toLocaleString('ro-RO', {
                                        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
                                    })
                                }}) este egal cu valoarea facturii
                                ({{ $parent.produseFactura.info.valoare }})
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                Vizualizare produse pentru <strong>{{
                                    $parent.produseFactura.info.tip_document
                                }}</strong>
                                &nbsp;
                                <strong>{{ $parent.produseFactura.info.numar_document }}</strong> /
                                <strong>{{ $parent.produseFactura.info.data_emitere }}</strong> in valoare de
                                <strong>{{ $parent.produseFactura.info.valoare }}</strong> RON
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th>Nume</th>
                                        <th>Unitate masura</th>
                                        <th>Cantitate</th>
                                        <th>Pret unitar</th>
                                        <th>TVA</th>
                                        <th>Pret total</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="arataAdaugare">
                                    <tr>
                                        <td><input ref="nume" type="text" name="nume" id="nume" class="form-control"
                                                   list="produse">
                                            <datalist id="produse">
                                                <option v-for="produs in $parent.produseDistincte" :value="produs.nume"
                                                        :key="produs.nume">
                                                    {{ produs.nume }}
                                                </option>
                                            </datalist>
                                        </td>
                                        <td>
                                            <select name="um" id="um" class="form-control" ref="um">
                                                <option v-for="(v,k) in unitatiMasura" :value="k" :key="k">{{
                                                        v
                                                    }}
                                                </option>
                                            </select>
                                        </td>
                                        <td><input ref="cantitate" type="text" name="cantitate" id="cantitate"
                                                   class="form-control">
                                        </td>
                                        <td><input ref="pret_unitar" type="text" name="pret_unitar" id="pret_unitar"
                                                   class="form-control"></td>
                                        <td>
                                            <select name="tva" id="tva" ref="tva" class="form-select">
                                                <option value="9">9</option>
                                                <option value="19">19</option>
                                            </select>
                                        </td>
                                        <td><input @focus="calculeazaTotal" ref="pret_total" type="text"
                                                   name="pret_total"
                                                   id="pret_total"
                                                   class="form-control">
                                        </td>
                                        <td>
                                            <button class="btn btn-primary"><strong>+</strong></button>
                                        </td>
                                    </tr>
                                    </tbody>

                                    <tfoot>
                                    <tr v-for="produs in $parent.produseFactura.produse" :key="produs.id"
                                        :data-produs="produs.id">
                                        <td nowrap>{{ produs.nume }}</td>
                                        <td nowrap>{{ unitatiMasura[produs.um] }}</td>
                                        <td nowrap>{{ produs.cantitate }}</td>
                                        <td nowrap>{{ produs.pret_unitar }}</td>
                                        <td nowrap>{{ produs.tva }}</td>
                                        <td nowrap>{{ produs.pret_total }}</td>
                                        <td><a href="#" @click.prevent="stergeFactura('produse', produs.id)">Sterge</a>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/config";
import {ref} from 'vue'
import {useAuthStore} from "@/store";
import stergeFactura from "@/minxins/stergeFactura";

export default {
    name: "ProduseFactura",
    mixins: [stergeFactura],
    props: {
        arataAdaugare: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    components: {},
    setup() {
        const um = ref({
            'kg': 'Kilograme',
            'l': 'Litri',
            'buc': 'Bucati',
            'leg': 'Legaturi',
            'borcane': 'borcane',
        })

        const authStore = useAuthStore()
        const answer = ref()
        const displayAnswer = ref(false)
        const alertSuccess = ref(false)
        const alertDanger = ref(false)

        return {
            unitatiMasura: um,
            authStore: authStore,
            answer,
            displayAnswer,
            alertSuccess,
            alertDanger,
        }
    },
    mounted() {
    },
    computed: {
        PretCalculat() {
            let ret = 0;
            //this.$parent.produseFactura.map(produs => ret = parseFloat(ret) + parseFloat(produs.pret_total));
            for (const idProdus in this.$parent.produseFactura.produse) {

                ret = parseFloat(ret) + parseFloat(this.$parent.produseFactura['produse'][idProdus].pret_total)
                // console.log(parseFloat(this.$parent.produseFactura['produse']))
            }
            console.log(typeof ret)
            return ret;
        }
    },
    methods: {

        adaugaProduseFactura() {
            let formBody = {
                id_factura: this.$parent.idFactura,
                nume: this.$refs.nume.value,
                um: this.$refs.um.value,
                cantitate: this.$refs.cantitate.value,
                pret_unitar: this.$refs.pret_unitar.value,
                tva: this.$refs.tva.value,
                pret_total: this.$refs.pret_total.value,
            }

            fetch(API_URL + '/produse/adauga-produs.php', {
                method: 'post',
                body: JSON.stringify(formBody),
                headers: {
                    'Content-type': 'application/json',
                    'Token': this.authStore.username.token,
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        formBody.id = answer.produs
                        this.$parent.produseFactura['produse'][answer.produs] = formBody


                        this.$refs.nume.value = ''
                        this.$refs.um.value = 'kg'
                        this.$refs.cantitate.value = ''
                        this.$refs.pret_unitar.value = ''
                        this.$refs.tva.value = '9'
                        this.$refs.pret_total.value = ''
                    }
                })
        },
        calculeazaTotal() {
            let t = 0,
                cantitate = parseFloat(this.$refs.cantitate.value),
                pu = parseFloat(this.$refs.pret_unitar.value),
                tva = parseFloat(this.$refs.tva.value);

            t = parseFloat(cantitate * (pu + (pu * tva / 100)))

            this.$refs.pret_total.value = t;
        }
    }
}
</script>

<style scoped>

</style>