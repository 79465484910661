<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-sm-6 offset-3">
                <form action="#" method="post" @submit.prevent="cautaProdus">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Produs" aria-label="produs" ref="produs">
                        <button type="submit" class="btn btn-outline-secondary">Cauta</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="row" v-if="rezultate">
            <div class="col-lg-12">
                <h3>Rezultate cautare</h3>

                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Produs</th>
                        <th>Unitate masura</th>
                        <th>Cantitate</th>
                        <th>Pret unitar</th>
                        <th>TVA</th>
                        <th>Pret total</th>
                        <th>Tip document</th>
                        <th>Numar document</th>
                        <th>Data emitere</th>
                        <th>Detalii</th>
                        <th>Furnizor</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="produs in rezultate" :key="produs.id">
                        <td>{{ produs.nume }}</td>
                        <td>{{ produs.um }}</td>
                        <td>{{ produs.cantitate }}</td>
                        <td>{{ produs.pret_unitar }}</td>
                        <td>{{ produs.tva }}</td>
                        <td>{{ produs.pret_total }}</td>
                        <td>{{ produs.tip_document }}</td>
                        <td><a href="#" @click="arataProduseFactura(produs.id_factura)">{{ produs.numar_document }}</a>
                        </td>
                        <td>{{ produs.data_emitere }}</td>
                        <td>{{ produs.detalii }}</td>
                        <td><a :href="'/furnizor/' + produs.id_furnizor">{{ produs.furnizor }}</a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="container-fluid" v-if="totalProduse">
        <div class="row">
            <div class="col-sm-4">
                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Produs</th>
                        <th>Cantitate</th>
                        <th>Pret total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(produs, hash) in totalProduse" :key="hash">
                        <td>{{ produs.nume }}</td>
                        <td>{{ produs.cantitate + ' ' + produs.um }}</td>
                        <td>{{
                                parseFloat(produs.pretTotal).toLocaleString('ro-RO', {
                                    style: 'decimal',
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3
                                })
                            }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <produse-factura :arata-adaugare="false"></produse-factura>

</template>

<script>
import {API_URL} from "@/config";
import {useAuthStore} from "@/store";
import {Modal} from "bootstrap";
import ProduseFactura from "@/components/de_plata/modals/ProduseFactura";

export default {
    name: "CautaProduse",
    components: {
        ProduseFactura
    },
    data() {
        const authStore = useAuthStore()
        return {
            rezultate: null,
            authStore,
            idFactura: null,
            produseFactura: {},
        }
    },
    computed: {
        totalProduse() {
            let ret = {};
            if (this.rezultate) {
                this.rezultate.forEach(produs => {

                    if (!Object.hasOwn(ret, produs.hash)) {
                        ret[produs.hash] = {
                            nume: produs.nume,
                            um: produs.um,
                            pretTotal: produs.pret_total,
                            cantitate: produs.cantitate,
                        }
                    } else {
                        ret[produs.hash].pretTotal = parseFloat(ret[produs.hash].pretTotal) + parseFloat(produs.pret_total);
                        ret[produs.hash].cantitate = parseFloat(ret[produs.hash].cantitate) + parseFloat(produs.cantitate);
                    }
                });
                return ret
            }

            return false;
        }
    },
    methods: {
        arataProduseFactura(idFactura) {
            this.idFactura = idFactura;

            fetch(API_URL + '/get-lista-produse.php', {
                method: 'post',
                body: JSON.stringify({id: idFactura}),
                headers: {
                    'Token': this.authStore.username.token,
                    'Content-Type': 'application/json',
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.produseFactura = answer.msg;
                        let mp = new Modal(document.querySelector('#modalAdaugaProduse'), {
                            backdrop: 'static'
                        });
                        mp.show();
                    } else {
                        alert('Nu am putut selecta produsele din factura');
                    }
                })
        },
        cautaProdus() {
            fetch(API_URL + '/produse/cauta-produs.php', {
                method: 'post',
                body: JSON.stringify({produs: this.$refs.produs.value}),
                headers: {
                    'Token': this.authStore.username.token,
                    'Content-type': 'application/json'
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.rezultate = answer.msg;
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>