<template>
    <div class="row">
        <div class="col">
            Total angajati: {{ Object.keys(plataAngajati['zi']).length }}
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4" v-for="tip in ['zi', 'final']" :key="tip">
            <h2 class="h4">Total plata la {{ tip }}</h2>
            <table class="table table-sm bg-success text-white">
                <tr v-for="(angajat,index, loopIndex) in plataAngajati[tip]" :key="index">
                    <td class="ps-1"><a :href="'/angajat/' + angajat.id"
                                        class="text-white text-decoration-none d-block">{{
                            (loopIndex + 1) + '. ' + angajat.nume
                        }}</a>
                    </td>
                    <td class="text-end pe-1">{{
                            parseFloat(angajat.dePlata).toLocaleString('ro-Ro', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })
                        }}
                    </td>
                </tr>
                <tr class="bg-danger">
                    <td class="ps-1">Total</td>
                    <td class="text-end pe-1">{{
                            parseFloat(totalDePlata[tip]).toLocaleString('ro-Ro', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })
                        }}
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <!--    <div class="row">
            <div class="col-lg-12">
                <table class="table table-sm table-bordered">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Prenume</th>
                        <th>Nume</th>
                        <th nowrap>Data inceput</th>
                        <th nowrap>Data sfarsit</th>
                        <th nowrap>Zile calendaristice</th>
                        <th nowrap>Zile lucrate</th>
                        <th nowrap>Zile neplatite</th>
                        <th nowrap>Plata pe zi</th>
                        <th nowrap>La zi</th>
                        <th nowrap>La final</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr is="vue:arata-angajat" v-for="(angajat, key, index) in listaAngajati"
                        :key="key"
                        :index="index"
                        :angajat="angajat"
                        :platit="false"
                        @zi-libera="ziLibera"></tr>
                    </tbody>
                </table>
            </div>
        </div>-->

</template>

<script>
import {API_URL} from '@/config';
// import ArataAngajat from "@/components/angajati/ArataAngajat";
import {useAuthStore} from "@/store";
import router from "@/router";

export default {
    name: "ListaAngajatiPlatiti",
    // components: {ArataAngajat},
    data() {
        return {
            listaAngajati: [],
            authStore: useAuthStore(),
        }
    },
    mounted() {
        this.getListaAngajati();
    },
    methods: {
        getListaAngajati() {
            let headers = {};
            if (this.authStore.username.token) {
                headers.Token = this.authStore.username.token;
            }
            fetch(API_URL + '/lista-angajati.php', {
                headers: headers
            })
                .then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.listaAngajati = answer.msg;
                    } else {
                        if (answer.msg.toLowerCase().includes('token')) {
                            this.authStore.username = null;
                            localStorage.removeItem('username');
                            router.push('/login');
                        }
                    }
                }).catch(e => console.log(e));
        },
        adaugaPlata(idAngajat) {
            this.$parent.idAngajat = idAngajat;
            this.$parent.selectedComponent = 'adauga-plata';
        },
        editeazaAngajat(idAngajat) {
            this.$parent.idAngajat = idAngajat;
            this.$parent.selectedComponent = 'editeaza-angajat';
        },
        ziLibera(idAngajat) {
            for (const key in this.listaAngajati) {
                if (this.listaAngajati[key].id === idAngajat) {
                    this.listaAngajati[key].zile_libere = parseInt(this.listaAngajati[key].zile_libere) + 1;
                }
            }

        }
    },
    computed: {
        plataAngajati() {
            let ret = {
                'zi': {},
                'final': {} //septembrie
            };

            for (const key in this.listaAngajati) {
                let angajat = this.listaAngajati[key];

                let plataIntermediara = 0;
                if (Object.hasOwn(angajat, 'plati') && Object.keys(angajat.plati).length > 0) {
                    for (const i in angajat['plati']) {
                        plataIntermediara = parseFloat(plataIntermediara) + parseFloat(angajat['plati'][i].plata);
                    }
                }
                // console.log(`${angajat.fname} ${angajat.lname} = ${angajat.de_plata_zi} - ${plataIntermediara} / ${angajat.data_sfarsit} / ${angajat.id}`)
                if ((angajat.de_plata_zi - plataIntermediara) !== 0) {
                    if (!Object.prototype.hasOwnProperty.call(ret, angajat.id)) {
                        ret['zi'][angajat.id] = {
                            //nume: angajat.fname + ' ' + angajat.lname,
                            nume: angajat.lname + ' ' + angajat.fname,
                            dePlata: angajat.de_plata_zi,
                            id: angajat.id,
                        }
                        ret['final'][angajat.id] = {
                            // nume: angajat.fname + ' ' + angajat.lname,
                            nume: angajat.lname + ' ' + angajat.fname,
                            dePlata: angajat.de_plata_septembrie,
                            id: angajat.id,
                        }
                    } else {
                        ret['zi'][angajat.id].dePlata = parseFloat(ret['zi'][angajat.id].dePlata) + parseFloat(angajat.de_plata_zi);
                        ret['final'][angajat.id].dePlata = parseFloat(ret['final'][angajat.id].dePlata) + parseFloat(angajat.de_plata_septembrie);
                    }

                    //minus ce s-a platit
                    ret['zi'][angajat.id].dePlata = parseFloat(ret['zi'][angajat.id].dePlata) - parseFloat(plataIntermediara);
                    ret['final'][angajat.id].dePlata = parseFloat(ret['final'][angajat.id].dePlata) - parseFloat(plataIntermediara);
                    // this.suma = parseFloat(this.suma) + parseFloat(ret[angajat.id].dePlata);
                }
            }
            return ret;
        },
        totalDePlata() {
            let suma = {
                'zi': 0,
                'final': 0,
            };
            for (const key in this.listaAngajati) {
                let angajat = this.listaAngajati[key];

                let plataIntermediara = 0;
                if (Object.hasOwn(angajat, 'plati') && Object.keys(angajat.plati).length > 0) {
                    for (const i in angajat['plati']) {
                        plataIntermediara = parseFloat(plataIntermediara) + parseFloat(angajat['plati'][i].plata);
                    }
                }

                suma['zi'] = parseFloat(suma['zi']) + parseFloat(angajat.de_plata_zi) - parseFloat(plataIntermediara);
                suma['final'] = parseFloat(suma['final']) + parseFloat(angajat.de_plata_septembrie) - parseFloat(plataIntermediara);
            }
            return suma;
        }
    }
}
</script>

<style scoped>

</style>