<template>
    <form action="#" @submit.prevent="adaugaProduseFactura">
        <h2 class="h3">Adauga produse</h2>

        <div v-if="produse.length > 0">
            <p class="alert alert-warning" v-if="PretCalculat < parseFloat($parent.valoareFactura)">
                Valoarea facturii ({{
                    parseFloat($parent.valoareFactura).toLocaleString('ro-RO', {
                        style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3
                    })
                }}) este mai mare decat pretul total calculat
                ({{
                    PretCalculat.toLocaleString('ro-RO', {
                        style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3
                    })
                }})
            </p>
            <p class="alert alert-warning" v-if="PretCalculat > parseFloat($parent.valoareFactura)">
                Pretul calculat ({{
                    PretCalculat.toLocaleString('ro-RO', {
                        style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3
                    })
                }}) este mai mare decat valoarea facturii
                ({{
                    parseFloat($parent.valoareFactura).toLocaleString('ro-RO', {
                        style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3
                    })
                }})
            </p>

            <p class="alert alert-success" v-if="PretCalculat == parseFloat($parent.valoareFactura)">
                Pretul calculat ({{
                    PretCalculat.toLocaleString('ro-RO', {
                        style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3
                    })
                }}) este egal cu valoarea facturii ({{
                    parseFloat($parent.valoareFactura).toLocaleString('ro-RO', {
                        style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3
                    })
                }})
            </p>
        </div>
        <table class="table">
            <thead>
            <tr>
                <th>Nume</th>
                <th>Unitate masura</th>
                <th>Cantitate</th>
                <th>Pret unitar</th>
                <th style="width: 100px;">TVA</th>
                <th>Pret total</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <input ref="nume" type="text" name="nume" id="nume" class="form-control" list="produse">
                    <datalist id="produse">
                        <option v-for="produs in produseDistincte" :value="produs.nume"
                                :key="produs.nume">
                            {{ produs.nume }}
                        </option>
                    </datalist>
                </td>
                <td>
                    <select name="um" id="um" class="form-select" ref="um">
                        <option v-for="(v,k) in um" :value="k" :key="k">{{ v }}</option>
                    </select>
                </td>
                <td><input ref="cantitate" type="text" name="cantitate" id="cantitate" class="form-control">
                </td>
                <td><input ref="pret_unitar" type="text" name="pret_unitar" id="pret_unitar"
                           class="form-control"></td>
                <td>
                    <select name="tva" ref="tva" id="tva" class="form-select">
                        <option value="9">9</option>
                        <option value="19">19</option>
                    </select>
                </td>
                <td><input @focus="calculeazaTotal" ref="pret_total" type="text" name="pret_total" id="pret_total"
                           class="form-control">
                </td>
                <td>
                    <button class="btn btn-primary"><strong>+</strong></button>
                </td>
            </tr>
            </tbody>

            <tfoot>
            <tr v-for="produs in produse" :key="produs.id" :data-produs="produs.id">
                <td nowrap>{{ produs.nume }}</td>
                <td nowrap>{{ um[produs.um] }}</td>
                <td nowrap>{{ produs.cantitate }}</td>
                <td nowrap>{{ produs.pret_unitar }}</td>
                <td nowrap>{{ produs.tva }}</td>
                <td nowrap>{{ produs.pret_total }}</td>
                <td><a href="#" @click.prevent="stergeFactura('produse', produs.id)">Sterge</a></td>
            </tr>
            </tfoot>
        </table>

    </form>
</template>

<script>
import {useAuthStore} from "@/store";
import {API_URL} from "@/config";
import stergeFactura from "@/minxins/stergeFactura";

export default {
    name: "AdaugaProdus",
    mixins: [stergeFactura],
    props: ['selecteazaProduse'],
    data() {
        const authStore = useAuthStore()
        return {
            authStore,
            idFactura: null,
            um: {
                'kg': 'Kilograme',
                'l': 'Litri',
                'buc': 'Bucati',
                'leg': 'Legaturi',
                'borcane': 'borcane',
            },
            produse: {},
            produseDistincte: null,
        }
    },
    mounted() {
        if (this.selecteazaProduse) {
            console.log(this.$parent.$parent.$parent.listaProduse)
        }
        this.getProduseDistincte()
    },
    computed: {
        PretCalculat() {
            let ret = 0;
            this.produse.forEach(produs => ret = parseFloat(ret) + parseFloat(produs.pret_total));
            return ret;
        }
    },
    methods: {
        getProduseDistincte() {
            fetch(API_URL + '/produse/toate-produsele.php', {
                method: 'get',
                headers: {
                    'Token': this.authStore.username.token
                }
            }).then(answer => answer.json())
                .then(answer => {
                    this.produseDistincte = answer.msg;
                })
        },
        adaugaProduseFactura() {
            let formBody = {
                id_factura: this.$parent.idFactura,
                nume: this.$refs.nume.value,
                um: this.$refs.um.value,
                cantitate: this.$refs.cantitate.value,
                pret_unitar: this.$refs.pret_unitar.value,
                tva: this.$refs.tva.value,
                pret_total: this.$refs.pret_total.value,
            }

            fetch(API_URL + '/produse/adauga-produs.php', {
                method: 'post',
                body: JSON.stringify(formBody),
                headers: {
                    'Content-type': 'application/json',
                    'Token': this.authStore.username.token,
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        formBody.id = answer.produs
                        if (Object.hasOwn(this.produse, answer.produs)) {
                            document.querySelector(`tr[data-produs="${answer.produs}"]`).style.display = ''
                        }
                        this.produse[answer.produs] = formBody

                        this.$refs.nume.value = ''
                        this.$refs.um.value = 'kg'
                        this.$refs.cantitate.value = ''
                        this.$refs.pret_unitar.value = ''
                        this.$refs.tva.value = '9'
                        this.$refs.pret_total.value = ''
                        console.log(this.produse)
                    }
                })
        },

        calculeazaTotal() {
            let t = 0,
                cantitate = parseFloat(this.$refs.cantitate.value),
                pu = parseFloat(this.$refs.pret_unitar.value),
                tva = parseFloat(this.$refs.tva.value);

            t = parseFloat(cantitate * (pu + (pu * tva / 100)))

            this.$refs.pret_total.value = t;
        }
    }
}
</script>

<style scoped>

</style>