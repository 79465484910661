<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 offset-lg-4">
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="username" class="form-label">Username</label>
                        <input type="text" name="username" id="username" class="form-control" ref="username">
                    </div>
                    <div class="mb-3">
                        <label for="parola" class="form-label">Parola</label>
                        <input type="password" name="password" id="parola" ref="parola" class="form-control">
                    </div>

                    <p v-if="error" class="alert alert-danger">{{ errorMessage }}</p>

                    <button type="submit" class="btn btn-primary float-end">Login</button>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {useAuthStore} from "@/store";
// import {router} from '@/router';

export default {
    name: "LoginView",
    data() {
        return {
            authStore: useAuthStore(),
            error: false,
            errorMessage: '',
        }
    },
    methods: {
        async login() {
            await this.authStore.login(this.$refs.username.value, this.$refs.parola.value)
                // .then(answer => answer.json())
                .catch(e => {
                    this.errorMessage = e;
                    this.error = true;
                })
        }
    }
}
</script>

<style scoped>

</style>