<template>
    <!--                <ul class="nav justify-content-center">-->
    <ul class="nav justify-content-center mainNav nav-tabs">
        <li class="nav-item">
            <a :class="{active: selectedComponent === 'lista-angajati', 'nav-link': true}"
               href="#"
               @click="setSelectedComponent('lista-angajati')">Lista angajati</a>
        </li>
        <li class="nav-item">
            <a :class="{active: selectedComponent === 'adauga-angajat', 'nav-link': true}"
               href="#"
               @click.prevent="setSelectedComponent('adauga-angajat')">Adauga angajat</a>
        </li>

        <li class="nav-item">
            <a :class="{active: selectedComponent === 'lista-angajati-platiti', 'nav-link': true}"
               href="#"
               @click.prevent="setSelectedComponent('lista-angajati-platiti')">Angajati platiti</a>
        </li>

        <li class="nav-item">
            <a :class="{active: selectedComponent === 'data-limita', 'nav-link': true}"
               href="#"
               @click.prevent="setSelectedComponent('data-limita')">Sfarsit sezon</a>
        </li>
    </ul>

    <div class="container-fluid mt-5">
        <component :is="selectedComponent"></component>
    </div>
</template>

<script>
import ListaAngajati from "@/components/angajati/ListaAngajati";
import AdaugaAngajat from "@/components/angajati/AdaugaAngajat";
import AdaugaPlata from "@/components/angajati/AdaugaPlata";
import EditeazaAngajat from "@/components/angajati/EditeazaAngajat";
import ListaAngajatiPlatiti from "@/components/angajati/ListaAngajatiPlatiti";
import DataLimita from "@/components/angajati/DataLimita";

export default {
    name: "PontajView",
    components: {
        ListaAngajati,
        AdaugaAngajat,
        AdaugaPlata,
        EditeazaAngajat,
        ListaAngajatiPlatiti,
        DataLimita
    },
    data() {
        return {
            idAngajat: null,
            selectedComponent: 'lista-angajati',
        }
    },
    methods: {
        setSelectedComponent(component) {
            this.selectedComponent = component;
        }
    }
}
</script>

<style scoped>
</style>