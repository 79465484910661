<template>
    <div class="modal" tabindex="-1" id="modalAdaugaPlata">
        <div class="modal-dialog">
            <form action="#" method="post" @submit.prevent="adaugaPlata">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Adauga incasare</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="$parent.dateFactura">
                        <div class="mb-3">
                            Factura <strong>{{ $parent.dateFactura.numar_factura }}</strong> din
                            <strong>{{ $parent.dateFactura.data_factura }}</strong> <br>
                            Emisa de: {{ $parent.dateFactura.furnizor }}<br>
                            Beneficiar: {{ $parent.dateFactura.beneficiar }}<br>
                            Valoare factura: <strong>{{ $parent.dateFactura.valoare_factura }}</strong><br>
                            Detalii: {{ $parent.dateFactura.detalii_factura }}
                        </div>

                        <div class="mb-3">
                            <label for="tip_plata" class="form-label">Tip document</label>
                            <select name="tip_plata" id="tip_plata" class="form-select" ref="tip_plata">
                                <option value="op">OP</option>
                                <option value="chitanta">Chitanta</option>
                                <option value="cash">Cash</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="numar_document" class="form-label">Numar document</label>
                            <input required type="text" name="numar_document" id="numar_document" class="form-control"
                                   ref="numar_document">
                        </div>

                        <div class="mb-3">
                            <label for="data_plata" class="form-label">Data platii</label>
                            <input required type="date" name="data_plata" id="data_plata" class="form-control"
                                   ref="data_plata">
                        </div>

                        <div class="mb-3">
                            <label for="valoare_plata" class="form-label">Valoare platita</label>
                            <input required type="number" name="valoare_plata" id="valoare_plata" class="form-control"
                                   ref="valoare_plata" step=".01" min="0">
                        </div>

                        <p v-if="displayAnswer"
                           :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                            {{ answer }}</p>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button :disabled="buttonDisabled" type="submit" class="btn btn-primary">Adauga plata</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
import {API_URL} from "@/config";

export default {
    name: "AdaugaPlata",
    data() {
        return {
            displayAnswer: false,
            alertSuccess: false,
            alertDanger: false,
            answer: '',
            buttonDisabled: false,
        }
    },
    methods: {
        adaugaPlata() {
            let body = {
                id_factura: this.$parent.dateFactura.id,
                numar_document: this.$refs.numar_document.value,
                tip_document: this.$refs.tip_plata.value,
                data_plata: this.$refs.data_plata.value,
                valoare_plata: this.$refs.valoare_plata.value,
            };
            if (new Date(body.data_plata) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/adauga-plata.php', {
                    method: 'post',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-type': 'application/json',
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;

                            console.log(this.$parent.listaFacturi)
                            console.log(this.$parent.listaFacturi[body.id_factura])
                            console.log(this.$parent.listaFacturi[body.id_factura].plati)
                            this.$parent.listaFacturi[body.id_factura].plati.push(body);
                            console.log(this.$parent.listaFacturi[body.id_factura])
                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;
                    })
                    .catch(e => console.log(e));
            }
        }
    }
}
</script>

<style scoped>

</style>