<template>
    <ul class="nav nav-tabs justify-content-center mainNav">
        <li class="nav-item">
            <a :class="{active: selectedComponent === 'listeaza-facturi-furnizori', 'nav-link': true}"
               href="#"
               @click="setSelectedComponent('listeaza-facturi-furnizori')">Facturi de platit</a>
        </li>
        <li class="nav-item">
            <a :class="{active: selectedComponent === 'adauga-factura-furnizori', 'nav-link': true}"
               href="#"
               @click.prevent="setSelectedComponent('adauga-factura-furnizori')">Adauga o factura</a>
        </li>
        <li class="nav-item">
            <a :class="{active: selectedComponent === 'listeaza-facturi-furnizori-platite', 'nav-link': true}"
               href="#"
               @click.prevent="setSelectedComponent('listeaza-facturi-furnizori-platite')">Facturi
                platite</a>
        </li>

        <li class="nav-item">
            <a :class="{active: selectedComponent === 'cauta-produse', 'nav-link': true}"
               href="#"
               @click.prevent="setSelectedComponent('cauta-produse')">Cauta produs</a>
        </li>

        <li class="nav-item">
            <a href="#"
               :class="{'nav-link': true, active: selectedComponent === 'lista-furnizori'}"
               @click.prevent="setSelectedComponent('lista-furnizori')"
            >Furnizori</a>
        </li>
    </ul>

    <component :is="selectedComponent" @plata-noua="plataNoua" @editeaza-factura="editeaza"
               v-bind="properties"></component>
</template>

<script>
import ListeazaFacturiFurnizori from "@/components/de_plata/ListeazaFacturiFurnizori";
import AdaugaFacturaFurnizori from "@/components/de_plata/AdaugaFacturaFurnizori";
import PlataNouaFurnizori from "@/components/de_plata/PlataNouaFurnizori";
import ListeazaFacturiFurnizoriPlatite from "@/components/de_plata/ListeazaFacturiFurnizoriPlatite";
import EditeazaFacturaFurnizori from "@/components/de_plata/EditeazaFacturaFurnizori";
import CautaProduse from "@/components/de_plata/CautaProduse";
import ListaFurnizori from "@/components/de_plata/ListaFurnizori";

export default {
    name: "FacturiFurnizoriView",
    components: {
        ListeazaFacturiFurnizori,
        AdaugaFacturaFurnizori,
        PlataNouaFurnizori,
        ListeazaFacturiFurnizoriPlatite,
        EditeazaFacturaFurnizori,
        CautaProduse,
        ListaFurnizori
    },
    data() {
        return {
            selectedComponent: 'listeaza-facturi-furnizori',
            selectedDestinatie: 'toate',
            idFactura: '',
        }
    },
    methods: {
        setSelectedComponent(component) {
            this.selectedComponent = component;
        },
        plataNoua(idFactura) {
            this.selectedComponent = 'plata-noua-furnizori';
            this.idFactura = idFactura;
        },
        editeaza(idFactura) {
            this.selectedComponent = 'editeaza-factura-furnizori'
            this.idFactura = idFactura
        }
    },
    computed: {
        properties() {
            if (this.selectedComponent === 'plata-noua-furnizori' || this.selectedComponent === 'editeaza-factura-furnizori') {
                return {idFactura: this.idFactura}
            }
            return {};
        }
    }
}
</script>

<style scoped>

</style>