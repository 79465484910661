<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-lg-4">
                <form action="#" method="post" id="formAdaugaFacturaFurnizori" @submit.prevent="adaugaFacturaFurnizori">
                    <h2 class="h3">Adauga factura furnizori</h2>

                    <div class="mb-3">
                        <label for="furnizor" class="form-label">Furnizor</label>
                        <input type="text" name="furnizor" id="furnizor" class="form-control" ref="furnizor"
                               list="furnizori" required>
                        <datalist id="furnizori">
                            <option :value="furnizor.nume" :key="furnizor.id" v-for="furnizor in listaFurnizori">
                                {{ furnizor.nume }}
                            </option>
                        </datalist>
                    </div>

                    <div class="mb-3">
                        <label for="beneficiar" class="form-label">Beneficiar</label>
                        <select name="beneficiar" id="beneficiar" class="form-select" ref="beneficiar" required>
                            <option v-for="firma in listaFirme" :key="firma.id" :value="firma.id">{{ firma.denumire }}
                            </option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="destinatie">Destinatie</label>
                        <select name="destinatie" id="destinatie" ref="destinatie" required class="form-control">
                            <option value=""></option>
                            <option v-for="destinatie in destinatiiFacturi" :key="destinatie" :value="destinatie">
                                {{ destinatie }}
                            </option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="tip_document">Tip Document</label>
                        <select name="tipFactura" id="tip_document" ref="tip_document" required class="form-select">
                            <option value=""></option>
                            <option value="aviz">Aviz</option>
                            <option value="factura">Factura</option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="numar_document">Numar document</label>
                        <input type="text" name="numar_document" id="numar_document" class="form-control"
                               ref="numar_document" required>
                    </div>

                    <div class="mb-3">
                        <label for="data_document">Data document</label>
                        <input type="date" name="data_document" id="data_document" class="form-control"
                               ref="data_document"
                               required>
                    </div>

                    <div class="mb-3">
                        <label for="valoare">Valoare</label>
                        <input type="number" name="valoare" id="valoare" class="form-control" ref="valoare" step=".01"
                               required>
                    </div>

                    <div class="mb-3">
                        <label for="detalii">Detalii</label>
                        <textarea name="detalii" id="detalii" cols="30" rows="3" class="form-control"
                                  ref="detalii"></textarea>
                    </div>

                    <p v-if="displayAnswer"
                       :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                        {{ answer }}</p>

                    <button :disabled="buttonDisabled" class="btn btn-primary">Salveaza</button>
                </form>
            </div>

            <div class="col-lg-8" v-if="adaugaProduse">
                <adauga-produs></adauga-produs>
            </div>
        </div>
    </div>
</template>

<script>
import listaFirme from "@/minxins/listaFirme";
import {API_URL} from "@/config";
import {useAuthStore} from "@/store";
import AdaugaProdus from "@/components/de_plata/components/AdaugaProdus";

export default {
    name: "AdaugaFacturaFurnizori",
    mixins: [listaFirme],
    components: {AdaugaProdus},
    data() {
        return {
            listaFirme: [],
            listaFurnizori: [],
            displayAnswer: false,
            alertSuccess: false,
            alertDanger: false,
            buttonDisabled: false,
            destinatiiFacturi: [
                'bar',
                'bucatarie',
                'diverse',
                'constructii',
            ],


            adaugaProduse: false,
            idFactura: '',
            valoareFactura: 0,
            authStore: useAuthStore(),
            displayAnswerProduse: false,
            alertSuccessProduse: false,
            alertDangerProduse: false,
            buttonDisabledProduse: false,
            answerProduse: '',
            produse: null
        }
    },
    mounted() {
        this.getListaFirme();
        this.getFurnizori();
    },
    methods: {
        adaugaProduseFactura() {
            const formBody = {
                id_factura: this.idFactura,
                produse: this.$refs.listaProduse.value
            }
            fetch(API_URL + '/adauga-produse.php', {
                method: 'post',
                body: JSON.stringify(formBody),
                headers: {
                    'Token': this.authStore.username.token
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.alertSuccessProduse = true
                    } else {
                        this.alertDangerProduse = true
                    }

                    this.answerProduse = answer.msg
                    this.displayAnswerProduse = true
                })
        },
        getFurnizori() {
            fetch(API_URL + '/get-lista-furnizori.php')
                .then(answer => answer.json())
                .then(answer => {
                    this.listaFurnizori = answer.msg;
                }).catch(e => console.log(e));
        },
        adaugaFacturaFurnizori() {
            const formBody = {
                furnizor: this.$refs.furnizor.value,
                beneficiar: this.$refs.beneficiar.value,
                tip_document: this.$refs.tip_document.value,
                numar_document: this.$refs.numar_document.value,
                data_document: this.$refs.data_document.value,
                valoare: this.$refs.valoare.value,
                detalii: this.$refs.detalii.value,
                destinatie: this.$refs.destinatie.value,
            }

            if (new Date(formBody.data_document) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/adauga-factura-furnizor.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': this.authStore.username.token,
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            let t = this.listaFurnizori.find(furnizor => furnizor.id === answer.furnizor.idFurnizor);
                            if (typeof t === 'undefined') {
                                this.listaFurnizori.push({
                                    id: answer.furnizor.idFurnizor,
                                    nume: answer.furnizor.nume
                                });
                            }
                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;

                            this.valoareFactura = formBody.valoare;

                            /*this.$refs.furnizor.value = '';
                            this.$refs.beneficiar.value = '';
                            this.$refs.tip_document.value = '';
                            this.$refs.numar_document.value = '';
                            this.$refs.data_document.value = '';
                            this.$refs.valoare.value = '';
                            this.$refs.detalii.value = '';
                            this.$refs.destinatie.value = '';*/

                            this.idFactura = answer.factura
                            this.adaugaProduse = true;

                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;
                    })
                    .catch(e => console.log(e));
            }
        }
    }
}
</script>

<style scoped>

</style>