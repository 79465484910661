import {defineStore} from 'pinia';
import {API_URL} from "@/config";
import router from '@/router';

export const useAuthStore = defineStore('authStore', {
    state: () => {
        return {
            username: JSON.parse(localStorage.getItem('username')),
        }
    },
    actions: {
        async login(username, password) {
            return new Promise((resolve, reject) => {
                if (username === '' || password === '') {
                    return reject('Userul si parola nu pot fi goale');
                } else {
                    fetch(API_URL + '/login.php', {
                        method: 'post',
                        body: JSON.stringify({username: username, password: password}),
                        headers: {
                            'Content-type': 'application/json'
                        }
                    }).then(answer => answer.json())
                        .then(answer => {
                            if (answer.error === false) {
                                this.username = answer.msg;
                                localStorage.setItem('username', JSON.stringify(answer.msg));
                                router.push('/')
                                //return resolve(JSON.stringify({'auth': true}));
                            } else {
                                return reject('User sau parola gresite');
                            }
                        }).catch(() => {
                        return reject('Invalid user sau parola');
                    })
                }
            });
        }
    }
});