<template>
    <div class="container-fluid">

        <div class="row mt-5">
            <div class="col-lg-12">
                <ul class="nav justify-content-center secondaryNav">
                    <li class="nav-item">
                        <a :class="{active: selectedComponent === 'neplatite', 'nav-link': true}"
                           href="#"
                           @click="setSelectedComponent('neplatite')">Facturi de platit</a>
                    </li>

                    <li class="nav-item">
                        <a :class="{active: selectedComponent === 'platite', 'nav-link': true}"
                           href="#"
                           @click.prevent="setSelectedComponent('platite')">Facturi
                            platite</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-12">

                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th nowrap>Furnizor</th>
                        <th nowrap>Beneficiar</th>
                        <th nowrap>Document</th>
                        <th nowrap>Numar document</th>
                        <th nowrap>Data</th>
                        <th nowrap>Valoare</th>
                        <th nowrap>Detalii</th>
                        <th nowrap></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr is="vue:afiseaza-factura-furnizori"
                        v-for="factura in infoFurnizor"
                        :key="factura.id"
                        :factura="factura"
                    ></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <adauga-plata-modal></adauga-plata-modal>
    <editeaza-factura-modal></editeaza-factura-modal>
    <produse-factura></produse-factura>
</template>

<script>
import {ref} from 'vue'
import {useRoute} from 'vue-router'
import {API_URL} from "@/config";
import {useAuthStore} from "@/store";
import AfiseazaFacturaFurnizori from "@/components/de_plata/AfiseazaFacturaFurnizori";
import AdaugaPlataModal from "@/components/de_plata/modals/AdaugaPlata";
import EditeazaFacturaModal from "@/components/de_plata/modals/EditeazaFactura";
import ProduseFactura from "@/components/de_plata/modals/ProduseFactura";
import {Modal} from 'bootstrap';

export default {
    name: "DetaliiFurnizorView",
    components: {
        AfiseazaFacturaFurnizori,
        AdaugaPlataModal,
        EditeazaFacturaModal,
        ProduseFactura,
    },
    setup() {
        const route = useRoute()
        const idFurnizor = ref(route.params.id);
        const authStore = useAuthStore();
        const infoFurnizor = ref();
        const modalPlata = ref();

        const idFactura = ref();
        const detaliiFactura = ref();
        const selectedComponent = ref('neplatite')
        const produseFactura = ref([]);
        const produseDistincte = ref()

        return {
            idFurnizor: idFurnizor,
            authStore: authStore,
            infoFurnizor: infoFurnizor,
            modalPlata: modalPlata,
            idFactura: idFactura,
            detaliiFactura: detaliiFactura,
            selectedComponent: selectedComponent,
            produseFactura: produseFactura,
            produseDistincte
        }
    },
    mounted() {
        this.getInfoFurnizor('neplatite')
    },
    methods: {

        setSelectedComponent(tip) {
            this.selectedComponent = tip;
            this.getInfoFurnizor(tip)
        },
        getInfoFurnizor(tip) {
            fetch(API_URL + '/get-info-furnizor.php?arata=' + tip, {
                method: 'post',
                body: JSON.stringify({id: this.idFurnizor}),
                headers: {
                    'Token': this.authStore.username.token
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.infoFurnizor = answer.msg
                    }
                })
        },
        plataNoua(idFactura) {
            this.idFactura = idFactura;

            fetch(API_URL + '/detalii-factura-furnizor.php', {
                method: 'post',
                body: JSON.stringify({idFactura: idFactura}),
                headers: {
                    'Content-Type': 'application/json',
                    'Token': this.authStore.username.token
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.detaliiFactura = answer.msg[0]
                        let mp = new Modal(document.querySelector('#modalAdaugaPlata'), {
                            backdrop: 'static'
                        });
                        mp.show();
                    } else {
                        alert('Nu am putut selecta datele facturii');
                    }
                });
        },
        editeazaFactura(idFactura) {
            this.idFactura = idFactura

            fetch(API_URL + '/detalii-factura-furnizor.php', {
                method: 'post',
                body: JSON.stringify({idFactura: idFactura}),
                headers: {
                    'Content-Type': 'application/json',
                    'Token': this.authStore.username.token
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.detaliiFactura = answer.msg[0]
                        let mp = new Modal(document.querySelector('#modalEditeazaFactura'), {
                            backdrop: 'static'
                        });
                        mp.show();
                    } else {
                        alert('Nu am putut selecta datele facturii');
                    }
                });
        },
        arataProduse(idFactura) {
            this.idFactura = idFactura;

            fetch(API_URL + '/get-lista-produse.php', {
                method: 'post',
                body: JSON.stringify({id: idFactura}),
                headers: {
                    'Token': this.authStore.username.token,
                    'Content-Type': 'application/json',
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.produseFactura = answer.msg;


                        fetch(API_URL + '/produse/toate-produsele.php', {
                            method: 'get',
                            headers: {
                                'Token': this.authStore.username.token
                            }
                        }).then(answer => answer.json())
                            .then(answer => {
                                this.produseDistincte = answer.msg;
                            })

                        let mp = new Modal(document.querySelector('#modalAdaugaProduse'), {
                            backdrop: 'static'
                        });
                        mp.show();

                    } else {
                        alert('Nu am putut selecta produsele din factura');
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>