<template>
    <div class="modal" tabindex="-1" id="modalEditeazaFactura">
        <div class="modal-dialog">
            <div class="modal-content">
                <form action="#" method="post" id="formAdaugaFacturaFurnizori"
                      @submit.prevent="editeazaFacturaFurnizori">

                    <div class="modal-header">
                        <h5 class="modal-title">Adauga plata furnizor</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="$parent.detaliiFactura">
                        <input type="hidden" name="id_factura" :value="$parent.idFactura"
                               ref="id_factura">
                        <h2 class="h3">Editeaza factura furnizori</h2>

                        <div class="mb-3">
                            <label for="destinatie">Destinatie</label>
                            <select name="destinatie" id="destinatie" ref="destinatie" required class="form-control">
                                <option value=""></option>
                                <option v-for="destinatie in destinatiiFacturi" :key="destinatie" :value="destinatie"
                                        :selected="destinatie === $parent.detaliiFactura.destinatie">
                                    {{ destinatie }}
                                </option>
                            </select>
                        </div>


                        <div class="mb-3">
                            <label for="tip_document">Tip Document</label>
                            <select name="tipFactura" id="tip_document" ref="tip_document" required class="form-select">
                                <option value=""></option>
                                <option value="aviz" :selected="'aviz' === $parent.detaliiFactura.tip_document">Aviz
                                </option>
                                <option value="factura" :selected="'factura' === $parent.detaliiFactura.tip_document">
                                    Factura
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="numar_document">Numar document</label>
                            <input type="text" name="numar_document" id="numar_document" class="form-control"
                                   ref="numar_document" required :value="$parent.detaliiFactura.numar_document">
                        </div>

                        <div class="mb-3">
                            <label for="valoare">Valoare</label>
                            <input type="number" name="valoare" id="valoare" class="form-control" ref="valoare"
                                   step=".01"
                                   required :value="$parent.detaliiFactura.valoare">
                        </div>

                        <div class="mb-3">
                            <label for="detalii">Detalii</label>
                            <textarea name="detalii" id="detalii" cols="30" rows="3" class="form-control"
                                      ref="detalii" :value="$parent.detaliiFactura.detalii"></textarea>
                        </div>

                        <p v-if="displayAnswer"
                           :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                            {{ answer }}</p>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Salveaza</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import {API_URL} from "@/config";
import {useAuthStore} from "@/store";

export default {
    name: "EditeazaFurnizor",
    setup() {
        const destinatiiFacturi = ref([
            'bar',
            'bucatarie',
            'diverse',
            'constructii',
        ])
        const authStore = useAuthStore();

        const displayAnswer = ref(false),
            alertSuccess = ref(false),
            alertDanger = ref(false)

        return {
            destinatiiFacturi,
            displayAnswer,
            alertDanger,
            alertSuccess,
            authStore
        }
    },
    methods: {
        editeazaFacturaFurnizori() {
            let formBody = {
                destinatie: this.$refs.destinatie.value,
                tip_document: this.$refs.tip_document.value,
                numar_document: this.$refs.numar_document.value,
                valoare: this.$refs.valoare.value,
                detalii: this.$refs.detalii.value,
                factura: this.$refs.id_factura.value,
            }

            fetch(API_URL + '/editeaza-factura-furnizor.php', {
                method: 'post',
                body: JSON.stringify(formBody),
                headers: {
                    'Content-Type': 'application/json',
                    'Token': this.authStore.username.token,
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {

                        this.alertSuccess = true;
                        this.alertDanger = false;

                        this.$parent.infoFurnizor[this.$parent.idFactura].destinatie = formBody.destinatie;
                        this.$parent.infoFurnizor[this.$parent.idFactura].tip_document = formBody.tip_document;
                        this.$parent.infoFurnizor[this.$parent.idFactura].numar_document = formBody.numar_document;
                        this.$parent.infoFurnizor[this.$parent.idFactura].valoare = formBody.valoare;
                        this.$parent.infoFurnizor[this.$parent.idFactura].detalii = formBody.detalii;

                    } else {
                        this.alertSuccess = false;
                        this.alertDanger = true;
                    }
                    this.answer = answer.msg;
                    this.displayAnswer = true;
                })
                .catch(e => console.log(e));

        }
    }
}
</script>

<style scoped>

</style>