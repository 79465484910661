<template>
    <div class="container-fluid">
        <div class="row mt-5">
            <div class="col-lg-12">
                <ul class="nav justify-content-center secondaryNav">
                    <li class="nav-item">
                        <a :class="{active: selectedComponent === 'neplatite', 'nav-link': true}"
                           href="#"
                           @click="setSelectedComponent('neplatite')">Facturi de platit</a>
                    </li>

                    <li class="nav-item">
                        <a :class="{active: selectedComponent === 'platite', 'nav-link': true}"
                           href="#"
                           @click.prevent="setSelectedComponent('platite')">Facturi
                            platite</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <h2>Lista facturi</h2>

                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th>Furnizor</th>
                        <th>Beneficiar</th>
                        <th>Document</th>
                        <th>Numar</th>
                        <th>Data</th>
                        <th>Valoare</th>
                        <th>Observatii</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr is="vue:afiseaza-detalii-factura"
                        v-for="factura in listaFacturi"
                        :key="factura.id"
                        :factura="factura"
                        :platit="false"
                    >
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <modal-adauga-plata></modal-adauga-plata>
</template>

<script>
import stergeFactura from "@/minxins/stergeFactura";
import {useRoute} from 'vue-router'
import {useAuthStore} from "@/store";
import {API_URL} from "@/config";
import AfiseazaDetaliiFactura from "@/components/de_incasat/v2/AfiseazaDetaliiFactura";
import {Modal} from "bootstrap";
import ModalAdaugaPlata from "@/components/de_incasat/v2/modal/AdaugaPlata"

export default {
    name: "AfiseazaFactura",
    components: {
        AfiseazaDetaliiFactura,
        ModalAdaugaPlata
    },
    data() {
        const route = useRoute()
        const authStore = useAuthStore()

        return {
            bgDanger: false,
            textWhite: false,
            bgSuccess: false,
            authStore,
            idBeneficiar: route.params.id,
            listaFacturi: null,
            selectedComponent: 'neplatite',
            dateFactura: null,
        }
    },
    mixins: [stergeFactura],
    mounted() {
        this.infoFactura('neplatite')

    },
    methods: {
        setSelectedComponent(tip) {
            this.selectedComponent = tip;
            this.infoFactura(tip)
        },
        infoFactura(tip) {
            // console.log(API_URL + '/de_incasat/get-info-factura.php')
            fetch(API_URL + '/de_incasat/get-info-factura.php?arata=' + tip, {
                method: 'post',
                body: JSON.stringify({idBeneficiar: this.idBeneficiar}),
                headers: {
                    'Token': this.authStore.username.token,
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.listaFacturi = answer.msg
                    }

                });
        },
        setSuccessBackground() {
            this.bgSuccess = true;
        },
        setDangerBackground() {
            this.bgDanger = true;
        },
        setWhiteText() {
            this.textWhite = true;
        },
        platesteFactura(idFactura) {
            this.dateFactura = this.listaFacturi[idFactura]
            let mp = new Modal(document.querySelector('#modalAdaugaPlata'), {
                backdrop: 'static'
            });
            mp.show();
        },
    }
}
</script>

<style scoped>
.platita {
}
</style>