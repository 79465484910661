<template>
    <div class="row">
        <div class="col-lg-6">
            <form action="#" method="post" @submit.prevent="adaugaAngajat">
                <h2 class="h3">Adauga angajat</h2>

                <div class="mb-3">
                    <label for="fname">Prenume</label>
                    <input type="text" name="fname" class="form-control" id="fname" ref="fname">
                </div>
                <div class="mb-3">
                    <label for="lname">Nume</label>
                    <input type="text" name="lname" id="lname" class="form-control" ref="lname" required>
                </div>

                <div class="mb-3">
                    <label for="data_inceput">Data inceput</label>
                    <input type="date" name="data_inceput" id="data_inceput" class="form-control" ref="data_inceput"
                           required>
                </div>

                <!--                <div class="mb-3">
                                    <label for="data_sfarsit">Data sfarsit</label>
                                    <input v-model="data_sfarsit" type="date" name="data_sfarsit" id="data_sfarsit" class="form-control"
                                           ref="data_sfarsit">
                                </div>-->

                <div class="mb-3">
                    <label for="plata">Suma plata / zi</label>
                    <input type="number" name="plata" id="plata" class="form-control" ref="plata" step=".01" required>
                </div>

                <p v-if="displayAnswer"
                   :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                    {{ answer }}</p>
                <button type="submit" class="btn btn-primary" :disabled="buttonDisabled">Adauga</button>
            </form>
        </div>
    </div>
</template>

<script>
import {API_URL} from '@/config';

export default {
    name: "AdaugaAngajat",
    data() {
        return {
            listaAngajati: [],
            alertSuccess: false,
            alertDanger: false,
            displayAnswer: false,
            answer: '',
            buttonDisabled: false,
            data_sfarsit: '',
        }
    },
    methods: {
        adaugaAngajat() {
            let formBody = {
                fname: this.$refs.fname.value,
                lname: this.$refs.lname.value,
                data_inceput: this.$refs.data_inceput.value,
                // data_sfarsit: this.$refs.data_sfarsit.value,
                plata: this.$refs.plata.value
            }
            if (new Date(formBody.data_inceput) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/adauga-angajat.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {

                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;

                            this.$refs.fname.value = '';
                            this.$refs.lname.value = '';
                            this.$refs.data_inceput.value = '';
                            this.$refs.plata.value = ''

                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;

                    }).catch(e => console.log(e));
            }
        }
    }
}
</script>

<style scoped>

</style>