<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-sm-8">
                <table class="table table-sm table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Furnizor</th>
                        <th>Fundal</th>
                        <th>Text</th>
                        <!--                    <th>Ordine afisare</th>-->
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="furnizor in listaFurnizori" :key="furnizor.id" :data-furnizor="furnizor.id">
                        <td><input type="text" :name="`nume[${furnizor.id}]`" :value="furnizor.nume"
                                   class="form-control form-control-sm" :ref="`nume[${furnizor.id}]`"></td>
                        <td><input type="color" :name="`fundal[${furnizor.id}]`" :value="furnizor.fundal"
                                   class="form-control form-control-sm" :ref="`fundal[${furnizor.id}]`"></td>
                        <td><input type="color" :name="`text[${furnizor.id}]`" :value="furnizor.text"
                                   class="form-control form-control-sm" :ref="`text[${furnizor.id}]`"></td>
                        <!--                    <td>
                                                <select :name="`furnizor[${furnizor.id}][display_order]`" class="form-control form-control-sm"
                                                        :ref="`display_order[${furnizor.id}]`">
                                                    <option v-for="i in 5" :key="i" :value="i" :selected="i == furnizor.display_order">
                                                        {{ i }}
                                                    </option>
                                                </select>
                                            </td>-->

                        <td nowrap>
                            <a class="btn btn-primary btn-sm me-1" href="#"
                               @click.prevent="modificaFurnizori(furnizor.id)">Salveaza
                                modificari</a>
                            <a class="btn btn-danger btn-sm" href="#"
                               @click.prevent="stergeFactura('lista-furnizori', furnizor.id)">Sterge</a>
                        </td>
                    </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/config";
import {useAuthStore} from "@/store";
import stergeFactura from "@/minxins/stergeFactura";

export default {
    name: "ListaFurnizori",
    mixins: [stergeFactura],
    data() {
        return {
            authStore: useAuthStore(),
            listaFurnizori: null,
        }
    },
    mounted() {
        this.getListaFurnizori()
    },
    methods: {
        getListaFurnizori() {
            fetch(API_URL + '/lista-furnizori-de-platit.php', {
                method: 'get',
                headers: {
                    'Token': this.authStore.username.token,
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.listaFurnizori = answer.msg;
                    }
                });
        },
        modificaFurnizori(idFurnizor) {
            const nume = this.$refs[`nume[${idFurnizor}]`][0].value,
                fundal = this.$refs[`fundal[${idFurnizor}]`][0].value,
                text = this.$refs[`text[${idFurnizor}]`][0].value,
                // displayOrder = this.$refs[`display_order[${idFurnizor}]`][0].value,
                displayOrder = 5,
                formBody = {
                    nume: nume,
                    fundal: fundal,
                    text: text,
                    display_order: displayOrder,
                    id: idFurnizor
                };

            fetch(API_URL + '/modifica-furnizor-de-platit.php', {
                method: 'post',
                body: JSON.stringify(formBody),
                headers: {
                    'Content-type': 'application/json',
                    'Token': this.authStore.username.token,
                }
            })
        }
    }
}
</script>

<style scoped>

</style>