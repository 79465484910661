import {API_URL} from '@/config'

export default {
    methods: {
        stergeFactura(tipFactura, idFactura) {
            // alert(idFactura + ' ' + tipFactura);
            if (confirm('Stergeti aceasta intrare?')) {
                let formBody = {
                    'tipFactura': tipFactura,
                    'idFactura': idFactura,
                };
                fetch(API_URL + '/sterge-factura.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            // window.location.reload();
                            if (answer.tip === 'factura') {
                                document.querySelectorAll('tr[data-factura="' + idFactura + '"]').forEach(elem => {
                                    elem.style.display = 'none';
                                });
                            } else if (answer.tip === 'plata') {
                                document.querySelector('tr[data-plata="' + idFactura + '"]').style.display = 'none';
                            } else if (answer.tip === 'angajat') {
                                document.querySelectorAll('tr[data-angajat="' + idFactura + '"]').forEach(elem => {
                                    elem.style.display = 'none';
                                });
                            } else if (answer.tip === 'zile_libere') {
                                document.querySelector('tr[data-zi-libera="' + idFactura + '"]').style.display = 'none';
                            } else if (answer.tip === 'produse') {
                                document.querySelector('tr[data-produs="' + idFactura + '"]').style.display = 'none';
                                // let elem = document.querySelector('tr[data-produs="' + idFactura + '"]');
                                // elem.parentNode.removeChild(elem)
                            } else if (answer.tip === 'lista-furnizori') {
                                document.querySelector('tr[data-furnizor="' + idFactura + '"]').style.display = 'none';
                            }
                            return true;
                        }

                    }).catch(e => console.log(e));
            }
        }
    }
}