<template>
    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <form action="#" @submit.prevent="salveazaData">
                <div class="input-group">
                    <input type="date" name="data_maxima" id="data_maxima" class="form-control" ref="data_maxima"
                           v-model="data_maxima">
                    <button type="submit" class="btn btn-primary">Salveaza</button>
                </div>
                <p v-if="displayAnswer"
                   :class="{alert: true,'mt-3': true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                    {{ answer }}</p>
            </form>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/config";

export default {
    name: "DataLimita",
    data() {
        return {
            answer: '',
            displayAnswer: false,
            alertSuccess: false,
            alertDanger: false,
            data_maxima: null
        }
    },
    mounted() {
        fetch(API_URL + '/get-data-maxima.php')
            .then(answer => answer.json())
            .then(answer => {
                if (answer.error === false) {
                    this.data_maxima = answer.msg;
                }
            })
    },
    methods: {
        salveazaData() {
            fetch(API_URL + '/salveaza-data-maxima.php', {
                method: 'post',
                body: JSON.stringify({data: this.$refs.data_maxima.value}),
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.alertSuccess = true;
                    } else {
                        this.alertDanger = true;
                    }

                    this.displayAnswer = true;
                    this.answer = answer.msg;
                })
        }
    }
}
</script>

<style scoped>

</style>