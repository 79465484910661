<template>
    <div class="modal" tabindex="-1" id="modalEditeazaAngajat">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <!--                    <h5 class="modal-title">Editeaza angajat</h5>-->
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="$parent.listaAngajati">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <form action="#" method="post" @submit.prevent="modificaAngajat">
                                    <h2 class="h3">Editeaza informatii angajat</h2>
                                    <div class="mb-3">
                                        <label for="fname">Prenume</label>
                                        <input type="text" name="fname" class="form-control" id="fname" ref="fname"
                                               :value="$parent.listaAngajati[$parent.idAngajat].fname">
                                    </div>
                                    <div class="mb-3">
                                        <label for="lname">Nume</label>
                                        <input type="text" name="lname" id="lname" class="form-control" ref="lname"
                                               required
                                               :value="$parent.listaAngajati[$parent.idAngajat].lname">
                                    </div>

                                    <div class="mb-3">
                                        <label for="data_inceput">Data inceput</label>
                                        <input type="date" name="data_inceput" id="data_inceput" class="form-control"
                                               ref="data_inceput"
                                               required :value="$parent.listaAngajati[$parent.idAngajat].data_inceput">
                                    </div>

                                    <div class="mb-3">
                                        <label for="data_sfarsit">Data sfarsit</label>
                                        <div class="input-group">

                                            <input type="date" name="data_sfarsit" id="data_sfarsit"
                                                   class="form-control"
                                                   ref="data_sfarsit"
                                                   :value="$parent.listaAngajati[$parent.idAngajat].data_sfarsit">
                                            <span class="input-group-text">
                                                <a href="#" @click.prevent="golesteZiSfarsit">goleste</a>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="plata">Suma plata / zi</label>
                                        <input type="number" name="plata" id="plata" class="form-control" ref="plata"
                                               step=".01"
                                               required
                                               :value="$parent.listaAngajati[$parent.idAngajat].pe_zi">
                                    </div>

                                    <p v-if="displayAnswer"
                                       :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                                        {{ answer }}</p>

                                    <button type="submit" class="btn btn-primary" :disabled="buttonDisabled">Modifica
                                    </button>

                                </form>
                            </div>

                            <div class="col-lg-6">
                                <form action="#" @submit.prevent="adaugaZiLibera">
                                    <h2 class="h3">Adauga liber</h2>
                                    <div class="mb-3">
                                        <label for="inceput_liber">Data inceput</label>
                                        <input type="date" name="data_inceput_liber" id="inceput_liber"
                                               class="form-control"
                                               ref="inceput_liber" v-model="azi">
                                    </div>

                                    <div class="mb-3">
                                        <label for="sfarsit_liber">Data sfarsit</label>
                                        <input type="date" name="data_sfarsit_liber" id="sfarsit_liber"
                                               class="form-control"
                                               ref="sfarsit_liber">
                                    </div>

                                    <div class="mb-3">
                                        <label for="motiv_liber">Motiv</label>
                                        <textarea name="motiv_liber" id="motiv_liber" cols="30" rows="5"
                                                  class="form-control"
                                                  ref="motiv_liber"></textarea>
                                    </div>

                                    <p v-if="displayAnswerLiber"
                                       :class="{alert: true, 'alert-success': alertSuccess, 'alert-danger': alertDanger}">
                                        {{ answer }}</p>
                                    <button type="submit" class="btn btn-primary">Adauga liber</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/config";

export default {
    name: "EditeazaAngajat",
    data() {
        return {
            alertSuccess: false,
            alertDanger: false,
            displayAnswer: false,
            answer: '',
            buttonDisabled: false,
            azi: new Date().toISOString().split('T')[0],
        }
    },
    methods: {
        golesteZiSfarsit() {
            this.$refs.data_sfarsit.value = '';
        },
        modificaAngajat() {
            const formBody = {
                fname: this.$refs.fname.value,
                lname: this.$refs.lname.value,
                data_inceput: this.$refs.data_inceput.value,
                data_sfarsit: this.$refs.data_sfarsit.value,
                plata: this.$refs.plata.value,
                id: this.$parent.idAngajat,
            }

            if (new Date(formBody.data_inceput) >= new Date()) {
                alert('Data aleasa nu poate sa fie in viitor');
            } else {
                fetch(API_URL + '/modifica-angajat.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {

                            this.alertSuccess = true;
                            this.alertDanger = false;
                            this.buttonDisabled = true;

                            this.$parent.listaAngajati[this.$parent.idAngajat].fname = formBody.fname;
                            this.$parent.listaAngajati[this.$parent.idAngajat].lname = formBody.lname;
                            this.$parent.listaAngajati[this.$parent.idAngajat].data_inceput = formBody.data_inceput;
                            this.$parent.listaAngajati[this.$parent.idAngajat].data_sfarsit = formBody.data_sfarsit;
                            this.$parent.listaAngajati[this.$parent.idAngajat].pe_zi = formBody.plata;
                                
                        } else {
                            this.alertSuccess = false;
                            this.alertDanger = true;
                        }
                        this.answer = answer.msg;
                        this.displayAnswer = true;

                    }).catch(e => console.log(e));
            }
        },

        adaugaZiLibera() {
            if (confirm('Adaugi zi libera pentru acest angajat?')) {
                let formBody = {
                    data_inceput: this.$refs.inceput_liber.value,
                    data_sfarsit: this.$refs.sfarsit_liber.value,
                    angajat: this.$parent.idAngajat,
                    motiv: this.$refs.motiv_liber.value,
                }

                fetch(API_URL + '/adauga-zi-libera.php', {
                    method: 'post',
                    body: JSON.stringify(formBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(answer => answer.json())
                    .then(answer => {
                        console.log(answer);
                    });
            }
        }
    }
}
</script>

<style scoped>

</style>