<template>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-lg-12">
                <table class="table table-sm table-bordered">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Prenume</th>
                        <th>Nume</th>
                        <th nowrap>Data inceput</th>
                        <th nowrap>Data sfarsit</th>
                        <th nowrap>Zile calendaristice</th>
                        <th nowrap>Zile lucrate</th>
                        <th nowrap>Zile neplatite</th>
                        <th nowrap>Plata pe zi</th>
                        <th nowrap>La zi</th>
                        <th nowrap>La final</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr is="vue:arata-angajat" v-for="(angajat, key, index) in listaAngajati"
                        :key="key"
                        :index="index"
                        :angajat="angajat"
                        :platit="false"
                        @zi-libera="ziLibera"></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <adauga-plata></adauga-plata>
    <editeaza-angajat></editeaza-angajat>
</template>

<script>
import ArataAngajat from "@/components/angajati/v2/ArataAngajat";
import {useRoute} from 'vue-router'
import {useAuthStore} from "@/store";
import {API_URL} from "@/config";
import {Modal} from "bootstrap";
import AdaugaPlata from "@/components/angajati/v2/modals/AdaugaPlata";
import EditeazaAngajat from "@/components/angajati/v2/modals/EditeazaAngajat";

export default {
    name: "InfoAngajat",
    data() {
        const authStore = useAuthStore()
        const route = useRoute()
        return {
            authStore,
            idAngajat: route.params.id,
            listaAngajati: null,
        }
    },
    components: {
        ArataAngajat,
        AdaugaPlata,
        EditeazaAngajat
    },
    mounted() {
        this.infoAngajat()
    },
    methods: {
        editeazaAngajat(idAngajat) {
            this.idAngajat = idAngajat;
            let m = new Modal(document.querySelector('#modalEditeazaAngajat'), {
                backdrop: 'static'
            });
            m.show();

            document.querySelector('#modalEditeazaAngajat').addEventListener('hidden.bs.modal', () => {
                window.location.reload()
            });
        },
        adaugaPlata(idAngajat) {
            //alert(idAngajat)
            this.idAngajat = idAngajat
            let m = new Modal(document.querySelector('#modalAdaugaPlataAngajat'), {
                backdrop: 'static'
            });
            m.show();
        },
        infoAngajat() {
            fetch(API_URL + '/angajati/lista-angajati.php', {
                method: 'post',
                body: JSON.stringify({idAngajat: this.idAngajat}),
                headers: {
                    'Content-type': 'application/json',
                    'Token': this.authStore.username.token,
                }
            }).then(answer => answer.json())
                .then(answer => {
                    if (answer.error === false) {
                        this.listaAngajati = answer.msg;
                        console.log(this.listaAngajati)
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>